import { GetterTree } from "vuex";
import { ClientProject } from "@/models/ClientProject";
import { ContactForm } from "@/models/ContactForm";
import { LeadStatistic, LeadStatisticExtended } from "@/models/LeadStatistic";
import { LeadStatus } from "@/models/LeadStatus";
import { AdTitlesByLeads, Assignee, MultiselectItem, SelectListItem } from "@/models/MultiselectItem";
import { LeadsState } from "@/models/states/LeadsState";
import { RootState } from "@/models/states/RootState";

const getters: GetterTree<LeadsState, RootState> = {
  adGroups(state): MultiselectItem[] {
    return state.adGroups;
  },

  adTitles(state): AdTitlesByLeads[] {
    return state.adTitles;
  },

  additionalStatuses(state): SelectListItem[] {
    return state.additionalStatuses;
  },

  assets(state): ClientProject[] {
    return state.assets;
  },

  assignees(state): Assignee[] {
    return state.assignees;
  },

  channels(state): MultiselectItem[] {
    return state.channels;
  },

  clients(state): MultiselectItem[] {
    return state.clients;
  },

  commitments(state): MultiselectItem[] {
    return state.commitments;
  },

  datePeriod(state): Date[] {
    return state.datePeriod;
  },

  leads(state): ContactForm[] {
    return state.leads;
  },

  leadsStatisticByAdditionalStatus(state): LeadStatistic[] {
    return state.leadsStatisticByAdditionalStatus;
  },

  leadsStatisticByCallStatus(state): LeadStatistic[] {
    return state.leadsStatisticByCallStatus;
  },

  leadsStatisticByCampaigns(state): LeadStatistic[] {
    return state.leadsStatisticByCampaigns;
  },

  leadsStatisticByPhoneAndForms(state): LeadStatistic[] {
    return state.leadsStatisticByPhoneAndForms;
  },

  leadsStatisticBySaleStatus(state): LeadStatistic[] {
    return state.leadsStatisticBySaleStatus;
  },

  leadsStatisticChannelMetadataType(state): LeadStatistic[] {
    return state.leadsStatisticChannelMetadataType;
  },

  leadsStatisticDealMeetingsLeads(state): LeadStatisticExtended[] {
    return state.leadsStatisticDealMeetingsLeads;
  },

  selectedAdditionalStatusesIds(state): number[] {
    return state.selectedAdditionalStatusesIds;
  },

  selectedAdGroupsIds(state): number[] {
    return state.selectedAdGroupsIds;
  },

  selectedAdTitles(state): string[] {
    return state.selectedAdTitles;
  },

  selectedAssetsIds(state): string[] {
    return state.selectedAssetsIds;
  },

  selectedAssigneesIds(state): number[] {
    return state.selectedAssigneesIds;
  },

  selectedCallStatusesIds(state): number[] {
    return state.selectedCallStatusesIds;
  },

  selectedChannelMetadataTypesIds(state): number[] {
    return state.selectedChannelMetadataTypesIds;
  },

  selectedChannelsIds(state): number[] {
    return state.selectedChannelsIds;
  },

  selectedClientsIds(state): number[] {
    return state.selectedClientsIds;
  },

  selectedCommitmentsIds(state): number[] {
    return state.selectedCommitmentsIds;
  },

  selectedLeadStatusesIds(state): LeadStatus[] {
    return state.selectedLeadStatusesIds;
  },

  selectedSaleStatusesIds(state): LeadStatus[] {
    return state.selectedSaleStatusesIds;
  },

  selectedTags(state): string[] {
    return state.selectedTags;
  },

  showDeleted(state): boolean {
    return state.showDeleted;
  },

  showFavourite(state): boolean {
    return state.showFavourite;
  },

  showNotActiveCampaign(state): boolean {
    return state.showNotActiveCampaign;
  },

  tags(state): MultiselectItem[] {
    return state.tags;
  },

  uniqueByCampaign(state): boolean {
    return state.uniqueByCampaign;
  },
};
export default getters;
