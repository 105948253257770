
































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { AdTitlesByLeads, Assignee, MultiselectItem, SelectListItem } from "@/models/MultiselectItem";
import ChannelService from "@/services/ChannelService";
import IconBase from "../IconBase.vue";
import IconTimesSmall from "../icons/IconTimesSmall.vue";
import { ClientProject } from "@/models/ClientProject";
import { LeadStatus } from "@/models/LeadStatus";
import LeadService from "@/services/LeadService";

const namespace = "leadsModule";

@Component({
  components: {
    IconBase,
    IconTimesSmall,
  },
})
export default class LeadsFiltersSelected extends Vue {
  @Getter("additionalStatuses", { namespace }) additionalStatuses!: SelectListItem[];
  @Getter("adGroups", { namespace }) adGroups!: MultiselectItem[];
  @Getter("adTitles", { namespace }) adTitles!: AdTitlesByLeads[];
  @Getter("assets", { namespace }) assets!: ClientProject[];
  @Getter("assignees", { namespace }) assignees!: Assignee[];
  @Getter("channels", { namespace }) channels!: MultiselectItem[];
  @Getter("clients", { namespace }) clients!: MultiselectItem[];
  @Getter("commitments", { namespace }) commitments!: MultiselectItem[];
  @Getter("selectedAdditionalStatusesIds", { namespace }) selectedAdditionalStatusesIds!: number[];
  @Getter("selectedAdGroupsIds", { namespace }) selectedAdGroupsIds!: number[];
  @Getter("selectedAdTitles", { namespace }) selectedAdTitles!: string[];
  @Getter("selectedAssetsIds", { namespace }) selectedAssetsIds!: string[];
  @Getter("selectedAssigneesIds", { namespace }) selectedAssigneesIds!: number[];
  @Getter("selectedCallStatusesIds", { namespace }) selectedCallStatusesIds!: number[];
  @Getter("selectedChannelMetadataTypesIds", { namespace }) selectedChannelMetadataTypesIds!: number[];
  @Getter("selectedChannelsIds", { namespace }) selectedChannelsIds!: number[];
  @Getter("selectedClientsIds", { namespace }) selectedClientsIds!: number[];
  @Getter("selectedCommitmentsIds", { namespace }) selectedCommitmentsIds!: number[];
  @Getter("selectedLeadStatusesIds", { namespace }) selectedLeadStatusesIds!: number[];
  @Getter("selectedSaleStatusesIds", { namespace }) selectedSaleStatusesIds!: LeadStatus[];
  @Getter("selectedTags", { namespace }) selectedTags!: string[];
  @Getter("tags", { namespace }) tags!: MultiselectItem[];
  @Action("setSelectedAdditionalStatusesIds", { namespace }) setSelectedAdditionalStatusesIds!: (selectedAdditionalStatusesIds: number[]) => void;
  @Action("setSelectedAdGroupsIds", { namespace }) setSelectedAdGroupsIds!: (selectedAdGroupsIds: number[]) => void;
  @Action("setSelectedAdTitles", { namespace }) setSelectedAdTitles!: (selectedAdTitles: string[]) => void;
  @Action("setSelectedAssetsIds", { namespace }) setSelectedAssetsIds!: (selectedAssetsIds: string[]) => void;
  @Action("setSelectedAssigneesIds", { namespace }) setSelectedAssigneesIds!: (selectedAssigneesIds: number[]) => void;
  @Action("setSelectedCallStatusesIds", { namespace }) setSelectedCallStatusesIds!: (selectedCallStatusesIds: number[]) => void;
  @Action("setSelectedChannelMetadataTypesIds", { namespace }) setSelectedChannelMetadataTypesIds!: (selectedChannelMetadataTypesIds: number[]) => void;
  @Action("setSelectedChannelsIds", { namespace }) setSelectedChannelsIds!: (selectedChannelsIds: number[]) => void;
  @Action("setSelectedClientsIds", { namespace }) setSelectedClientsIds!: (selectedClientsIds: number[]) => void;
  @Action("setSelectedCommitmentsIds", { namespace }) setSelectedCommitmentsIds!: (selectedCommitmentsIds: number[]) => void;
  @Action("setSelectedLeadStatusesIds", { namespace }) setSelectedLeadStatusesIds!: (selectedLeadStatusesIds: number[]) => void;
  @Action("setSelectedSaleStatusesIds", { namespace }) setSelectedSaleStatusesIds!: (selectedSaleStatusesIds: LeadStatus[]) => void;
  @Action("setSelectedTags", { namespace }) setSelectedTags!: (selectedTags: string[]) => void;

  get selectedAdditionalStatuses(): SelectListItem[] {
    return this.additionalStatuses.filter((i: SelectListItem) => this.selectedAdditionalStatusesIds.indexOf(+i.Value) >= 0);
  }

  get selectedAdGroups(): MultiselectItem[] {
    return this.adGroups.filter((i: MultiselectItem) => this.selectedAdGroupsIds.indexOf((i.ID as any) as number) >= 0);
  }

  get selectedAdTitlesFiltered(): AdTitlesByLeads[] {
    const adTitlesFiltered = this.adTitles.filter((i: AdTitlesByLeads) => this.selectedAdTitles.indexOf(i.AdTitle) >= 0);
    const uniqueAdTitlesFiltered = [...new Map(adTitlesFiltered.map((i: AdTitlesByLeads) => [i.AdTitle, i])).values()];
    return uniqueAdTitlesFiltered;
  }

  get selectedAssets(): ClientProject[] {
    return this.assets.filter((i: ClientProject) => this.selectedAssetsIds.indexOf(i.AssetId) >= 0);
  }

  get selectedAssignees(): Assignee[] {
    return this.assignees.filter((i: Assignee) => this.selectedAssigneesIds.indexOf(i.SalesmanID) >= 0);
  }

  get selectedCallStatuses(): MultiselectItem[] {
    return LeadService.getCallStatuses().filter((i: MultiselectItem) => this.selectedCallStatusesIds.indexOf(+i.ID) >= 0);
  }

  get selectedChannelMetadataTypes(): MultiselectItem[] {
    return ChannelService.getChannelMetadataTypes().filter((i: MultiselectItem) => this.selectedChannelMetadataTypesIds.indexOf((i.ID as any) as number) >= 0);
  }

  get selectedChannels(): MultiselectItem[] {
    return this.channels.filter((i: MultiselectItem) => this.selectedChannelsIds.indexOf((i.ID as any) as number) >= 0);
  }

  get selectedClients(): MultiselectItem[] {
    return this.clients.filter((i: MultiselectItem) => this.selectedClientsIds.indexOf((i.ID as any) as number) >= 0);
  }

  get selectedCommitments(): MultiselectItem[] {
    return this.commitments.filter((i: MultiselectItem) => this.selectedCommitmentsIds.indexOf((i.ID as any) as number) >= 0);
  }

  get selectedLeadStatuses(): MultiselectItem[] {
    return LeadService.getAvailableLeadStatuses().filter((i: MultiselectItem) => this.selectedLeadStatusesIds.indexOf((i.ID as any) as number) >= 0);
  }

  get selectedSaleStatuses(): MultiselectItem[] {
    return LeadService.getAvailableSaleStatuses().filter((i: MultiselectItem) => this.selectedSaleStatusesIds.indexOf(+i.ID) >= 0);
  }

  get selectedTagsFiltered(): MultiselectItem[] {
    return this.tags.filter((i: MultiselectItem) => this.selectedTags.indexOf(i.Title) >= 0);
  }

  onSelectedAdditionalStatusRemove(selectedAdditionalStatusesId: number): void {
    const selectedAdditionalStatusesIdsNew: number[] = this.selectedAdditionalStatusesIds.filter((i) => i !== selectedAdditionalStatusesId);
    this.setSelectedAdditionalStatusesIds(selectedAdditionalStatusesIdsNew);
  }

  onSelectedAdGroupRemove(selectedAdGroupsId: number): void {
    const selectedAdGroupsIdsNew: number[] = this.selectedAdGroupsIds.filter((i) => i !== selectedAdGroupsId);
    this.setSelectedAdGroupsIds(selectedAdGroupsIdsNew);
  }

  onSelectedAdTitleRemove(selectedAdTitle: string): void {
    const selectedAdTitlesNew: string[] = this.selectedAdTitles.filter((i) => i !== selectedAdTitle);
    this.setSelectedAdTitles(selectedAdTitlesNew);
  }

  onSelectedAssetRemove(selectedAssetsId: string): void {
    const selectedAssetsIdsNew: string[] = this.selectedAssetsIds.filter((i) => i !== selectedAssetsId);
    this.setSelectedAssetsIds(selectedAssetsIdsNew);
  }

  onSelectedAssigneeRemove(selectedAssigneesId: number): void {
    const selectedAssigneesIdsNew: number[] = this.selectedAssigneesIds.filter((i) => i !== selectedAssigneesId);
    this.setSelectedAssigneesIds(selectedAssigneesIdsNew);
  }

  onSelectedCallStatusRemove(selectedCallStatusesId: number): void {
    const selectedCallStatusesIdsNew: number[] = this.selectedCallStatusesIds.filter((i) => i !== selectedCallStatusesId);
    this.setSelectedCallStatusesIds(selectedCallStatusesIdsNew);
  }

  onSelectedChannelMetadataTypeRemove(selectedChannelMetadataTypeId: number): void {
    const selectedChannelMetadataTypesIdsNew: number[] = this.selectedChannelMetadataTypesIds.filter((i) => i !== selectedChannelMetadataTypeId);
    this.setSelectedChannelMetadataTypesIds(selectedChannelMetadataTypesIdsNew);
  }

  onSelectedChannelRemove(selectedChannelsId: number): void {
    const selectedChannelsIdsNew: number[] = this.selectedChannelsIds.filter((i) => i !== selectedChannelsId);
    this.setSelectedChannelsIds(selectedChannelsIdsNew);
  }

  onSelectedClientRemove(selectedClientsId: number): void {
    const selectedClientsIdsNew: number[] = this.selectedClientsIds.filter((i) => i !== selectedClientsId);
    this.setSelectedClientsIds(selectedClientsIdsNew);
  }

  onSelectedCommitmentRemove(selectedCommitmentsId: number): void {
    const selectedCommitmentsIdsNew: number[] = this.selectedCommitmentsIds.filter((i) => i !== selectedCommitmentsId);
    this.setSelectedCommitmentsIds(selectedCommitmentsIdsNew);
  }

  onSelectedLeadStatusRemove(selectedLeadStatusesId: number): void {
    const selectedLeadStatusesIdsNew: number[] = this.selectedLeadStatusesIds.filter((i) => i !== selectedLeadStatusesId);
    this.setSelectedLeadStatusesIds(selectedLeadStatusesIdsNew);
  }

  onSelectedSaleStatusRemove(selectedSaleStatusesId: LeadStatus): void {
    const selectedSaleStatusesIdsNew: LeadStatus[] = this.selectedSaleStatusesIds.filter((i) => i !== selectedSaleStatusesId);
    this.setSelectedSaleStatusesIds(selectedSaleStatusesIdsNew);
  }

  onSelectedTagRemove(selectedTag: string): void {
    const selectedTagsNew: string[] = this.selectedTags.filter((i) => i !== selectedTag);
    this.setSelectedTags(selectedTagsNew);
  }
}
