

































































import { Component, Vue } from "vue-property-decorator";
import IconBase from "../IconBase.vue";
import IconPencil from "../icons/IconPencil.vue";
import IconMobileArrow from "../icons/IconMobileArrow.vue";

@Component({
  components: {
    IconBase,
    IconPencil,
    IconMobileArrow,
  },
})
export default class SmartphonesGridContent extends Vue {
  connectionAction = true;
  smartphoneOn = true;

  emitToggleSmartphoneEdit(): void {
    this.$emit("toggle-smartphone-edit");
  }

  data = [
    {
      id: "232323",
      smartphone: "972772316351",
      receive_call: "0526985632",
      client: "Lead test client - Don't change ot delete",
      date: "31/05/2020 20:04",
      calls_number: "1",
      advertising_channel: "testcamp-01102017-19007",
      channel_name: "Direct",
      project: "שם הפרויקט",
      answered_calls: "1",
      missed_calls: "1",
      percentage_response: "2%",
      average_call: "1:02",
      status: 1,
      actions: [{}],
    },
    {
      id: "232324",
      smartphone: "972772316351",
      receive_call: "0526985632",
      client: "Lead test client - Don't change ot delete",
      date: "31/05/2020 20:04",
      calls_number: "1",
      advertising_channel: "testcamp-01102017-19007",
      channel_name: "Direct",
      project: "שם הפרויקט",
      answered_calls: "1",
      missed_calls: "1",
      percentage_response: "2%",
      average_call: "1:02",
      status: 2,
      actions: [{}],
    },
    {
      id: "232325",
      smartphone: "972772316351",
      receive_call: "0526985632",
      client: "Lead test client - Don't change ot delete",
      date: "31/05/2020 20:04",
      calls_number: "1",
      advertising_channel: "testcamp-01102017-19007",
      channel_name: "Direct",
      project: "שם הפרויקט",
      answered_calls: "1",
      missed_calls: "1",
      percentage_response: "2%",
      average_call: "1:02",
      status: 1,
      actions: [{}],
    },
    {
      id: "232326",
      smartphone: "972772316351",
      receive_call: "0526985632",
      client: "Lead test client - Don't change ot delete",
      date: "31/05/2020 20:04",
      calls_number: "1",
      advertising_channel: "testcamp-01102017-19007",
      channel_name: "Direct",
      project: "שם הפרויקט",
      answered_calls: "1",
      missed_calls: "1",
      percentage_response: "2%",
      average_call: "1:02",
      status: 2,
      actions: [{}],
    },
  ];
}
