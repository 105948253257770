










import { Component, Vue } from "vue-property-decorator";
// import ChannelsFiltersSelected from "./ChannelsFiltersSelected.vue";
import ChannelsFiltersDropdowns from "./ChannelsFiltersDropdowns.vue";
import ChannelsFiltersDate from "./ChannelsFiltersDate.vue";

@Component({
  components: {
    // ChannelsFiltersSelected,
    ChannelsFiltersDropdowns,
    ChannelsFiltersDate,
  },
})
export default class ChannelsFilters extends Vue {
  ChannelsFilter1SelectedOptions = [1, 2];
}
