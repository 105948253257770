













import { Component, Vue } from "vue-property-decorator";
import ChannelsHeadline from "../components/Channels/ChannelsHeadline.vue";
import ChannelsFilters from "../components/Channels/ChannelsFilters.vue";
import ChannelsGraphs from "../components/Channels/ChannelsGraphs.vue";
import ChannelsGrid from "../components/Channels/ChannelsGrid.vue";
import VueMq from "vue-mq";

Vue.use(VueMq, {
  breakpoints: {
    sm: 576,
    md: 992,
    lg: Infinity,
  },
  defaultBreakpoint: "sm",
});

@Component({
  components: {
    ChannelsHeadline,
    ChannelsFilters,
    ChannelsGraphs,
    ChannelsGrid,
  },
})
export default class Channels extends Vue {
  public channelsGraphsVisible = true;

  public toggleGraphs(): void {
    this.channelsGraphsVisible = !this.channelsGraphsVisible;
  }
}
