import { Module } from "vuex";
import { LeadsState } from "@/models/states/LeadsState";
import { RootState } from "@/models/states/RootState";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const leadsModule: Module<LeadsState, RootState> = {
  namespaced: true,
  state: {
    showDeleted: false,
    showFavourite: false,
    showNotActiveCampaign: false,
    uniqueByCampaign: false,
    adGroups: [],
    adTitles: [],
    additionalStatuses: [],
    assets: [],
    assignees: [],
    channels: [],
    clients: [],
    commitments: [],
    datePeriod: [],
    leads: [],
    leadsStatisticByAdditionalStatus: [],
    leadsStatisticByCallStatus: [],
    leadsStatisticByCampaigns: [],
    leadsStatisticByPhoneAndForms: [],
    leadsStatisticBySaleStatus: [],
    leadsStatisticChannelMetadataType: [],
    leadsStatisticDealMeetingsLeads: [],
    selectedAdditionalStatusesIds: [],
    selectedAdGroupsIds: [],
    selectedAdTitles: [],
    selectedAssetsIds: [],
    selectedAssigneesIds: [],
    selectedCallStatusesIds: [],
    selectedChannelMetadataTypesIds: [],
    selectedChannelsIds: [],
    selectedClientsIds: [],
    selectedCommitmentsIds: [],
    selectedLeadStatusesIds: [],
    selectedSaleStatusesIds: [],
    selectedTags: [],
    tags: [],
  },
  getters,
  mutations,
  actions,
};
export default leadsModule;
