











































import { Component, Prop, Vue } from "vue-property-decorator";
import IconBase from "../IconBase.vue";
import IconSearch from "../icons/IconSearch.vue";
import IconCaretDown from "../icons/IconCaretDown.vue";
import IconExcel from "../icons/IconExcel.vue";
import { LeadsGridColumn } from "../../models/LeadsGridColumn";
import VueMq from "vue-mq";

Vue.use(VueMq, {
  breakpoints: {
    sm: 576,
    md: 992,
    lg: Infinity,
  },
  defaultBreakpoint: "sm",
});

@Component({
  components: {
    IconBase,
    IconSearch,
    IconCaretDown,
    IconExcel,
  },
})
export default class LeadsGridHeadline extends Vue {
  @Prop({ default: [] }) readonly columns!: Array<LeadsGridColumn>;

  showSelectColumns = false;
}
