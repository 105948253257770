























import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { UserData } from "@/models/UserData";
import ApiService from "@/services/ApiService";
import ConfigService from "@/services/ConfigService";
import IconBase from "../IconBase.vue";
import IconUser from "../icons/IconUser.vue";
import IconCaretDown from "../icons/IconCaretDown.vue";

const namespace = "mainModule";

@Component({
  components: {
    IconBase,
    IconUser,
    IconCaretDown,
  },
})
export default class HeaderUser extends Vue {
  //#region Vuex getters and actions

  @Getter("userData", { namespace }) userData!: UserData;

  //#endregion Vuex getters and actions

  changeLocale(locale: string): void {
    this.$i18n.locale = locale;
    localStorage.locale = locale;
  }

  logout() {
    ApiService.logout().then(() => {
      location.href = ConfigService.loginUrl;
    });
  }
}
