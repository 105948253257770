































































































import { Component, Vue } from "vue-property-decorator";
import VueCookies from "vue-cookies";
import Fragment from "vue-fragment";
import IconBase from "../IconBase.vue";
import IconCaretDown from "../icons/IconCaretDown.vue";

Vue.use(Fragment.Plugin);
Vue.use(VueCookies);

@Component({
  components: {
    IconBase,
    IconCaretDown,
  },
})
export default class ChannelsFiltersDropdowns extends Vue {
  ChannelsFilter1Selected = [];
  ChannelsFilter1Height = 200;
  ChannelsFilter1Current = { text: "שם לקוח: נבחרו" };
  ChannelsFilter1Options = [
    { value: "option1", text: "1" },
    { value: "option2", text: "2" },
    { value: "option3", text: "3" },
    { value: "option4", text: "4" },
  ];
  ChannelsFilter2Current = { text: "סטטוס" };
  ChannelsFilter2Options = [
    { status: 0, text: "הכל1" },
    { status: 1, text: "פעיל" },
    { status: 2, text: "הסתיים" },
    { status: 3, text: "מושהה" },
    { status: 4, text: "בעיית חיבור" },
  ];

  ChannelsFilter3Selected = [];
  ChannelsFilter3Current = { text: "אפיק פרסומי" };
  ChannelsFilter3Options = [
    { logo: "google-ads", text: "Google ads" },
    { logo: "facebook", text: "Facebook ads" },
    { logo: "facebook", text: "Facebook leads" },
    { logo: "instagram", text: "Instagram ads" },
    { logo: "instagram", text: "Instagram leads" },
    { logo: "phone", text: "Phone" },
    { logo: "fixdigital", text: "Referral" },
  ];

  ChannelsFilter4Selected = [];
  ChannelsFilter4Current = { text: "חשבון מודעות" };
  ChannelsFilter4Options = [
    { logo: "google-ads", status: 1, text: "123454656456654" },
    { logo: "google-ads", status: 2, text: "123454656456654" },
    { logo: "facebook", status: 3, text: "123454656456654" },
    { logo: "facebook", status: 4, text: "123454656456654" },
  ];

  ChannelsFilter5Selected = [];
  ChannelsFilter5Current = { text: "שם לקוח" };
  ChannelsFilter5Options = [{ text: "אופציה1" }, { text: "אופציה2" }, { text: "אופציה3" }];
}
