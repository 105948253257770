











































import VueMq from "vue-mq";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { ContactForm } from "@/models/ContactForm";
import ApiService, { LeadsPayload } from "@/services/ApiService";
import IconBase from "../IconBase.vue";
import IconSearch from "../icons/IconSearch.vue";
import IconCaretDown from "../icons/IconCaretDown.vue";
import IconExcel from "../icons/IconExcel.vue";
import { LeadsGridColumn } from "../../models/LeadsGridColumn";

Vue.use(VueMq, {
  breakpoints: {
    sm: 576,
    md: 992,
    lg: Infinity,
  },
  defaultBreakpoint: "sm",
});

const namespace = "leadsModule";

@Component({
  components: {
    IconBase,
    IconSearch,
    IconCaretDown,
    IconExcel,
  },
})
export default class LeadsGridHeadline extends Vue {
  @Prop({ default: [] }) readonly columns!: Array<LeadsGridColumn>;
  @Getter("leads", { namespace }) leads!: ContactForm[];

  showSelectColumns = false;

  onExportLeadsToExcelClick() {
    ApiService.exportLeadsToExcel(this.leads, this.columns)
      .then((fileUrl: string) => {
        window.open(fileUrl);
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
