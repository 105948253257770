










import { Component, Vue } from "vue-property-decorator";
import LeadsFiltersSelected from "./LeadsFiltersSelected.vue";
import LeadsFiltersDropdowns from "./LeadsFiltersDropdowns.vue";
import LeadsFiltersDate from "./LeadsFiltersDate.vue";

@Component({
  components: {
    LeadsFiltersSelected,
    LeadsFiltersDropdowns,
    LeadsFiltersDate,
  },
})
export default class LeadsFilters extends Vue {
  LeadsFilter1SelectedOptions = [1, 2];
}
