

















import VueMq from "vue-mq";
import { Component, Vue } from "vue-property-decorator";
import SmartphonesGridHeadline from "./SmartphonesGridHeadline.vue";
import SmartphonesGridContent from "./SmartphonesGridContent.vue";
import IconBase from "../IconBase.vue";
import IconSearch from "../icons/IconSearch.vue";
import IconCaretDown from "../icons/IconCaretDown.vue";
import IconCaretRight from "../icons/IconCaretRight.vue";
import IconCaretLeft from "../icons/IconCaretLeft.vue";

Vue.use(VueMq, {
  breakpoints: {
    sm: 576,
    md: 992,
    lg: Infinity,
  },
  defaultBreakpoint: "sm",
});

@Component({
  components: {
    SmartphonesGridHeadline,
    SmartphonesGridContent,
    IconBase,
    IconSearch,
    IconCaretDown,
    IconCaretRight,
    IconCaretLeft,
  },
})
export default class SmartphonesGrid extends Vue {
  // showSmartphoneEdit() {
  //   this.$emit('showSmartphoneEdit');
  // }
}
