import { DialogProgrammatic as Dialog } from "buefy";
import i18n from "@/i18n";
import { AvailableChannelMetadataTypes, ChannelMetadataType } from "@/models/ChannelMetadataType";
import { MultiselectItem } from "@/models/MultiselectItem";

export default {
  showError(message: string) {
    Dialog.alert({
      title: i18n.t("Error").toString(),
      message,
      type: "is-danger",
      ariaRole: "alertdialog",
      ariaModal: true,
    });
  },

  showMessage(title: string, message: string) {
    Dialog.alert({
      title,
      message,
      confirmText: "OK",
    });
  },
};
