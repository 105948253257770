import { ActionTree } from "vuex";
import { ClientProject } from "@/models/ClientProject";
import { ContactForm } from "@/models/ContactForm";
import { LeadStatistic } from "@/models/LeadStatistic";
import { LeadStatus } from "@/models/LeadStatus";
import { AdTitlesByLeads, Assignee, MultiselectItem, SelectListItem } from "@/models/MultiselectItem";
import { LeadsState } from "@/models/states/LeadsState";
import { RootState } from "@/models/states/RootState";
import ApiService, { CommonMultiselectPayload, LeadsPayload, LeadsStatisticByCampaignsPayload } from "@/services/ApiService";

const actions: ActionTree<LeadsState, RootState> = {
  clearAdditionalStatuses({ commit }): void {
    commit("setAdditionalStatuses", []);
  },

  clearCommitments({ commit }): void {
    commit("setCommitments", []);
  },

  getAdGroups({ commit }, payload: CommonMultiselectPayload): Promise<MultiselectItem[]> {
    return new Promise((resolve, reject) => {
      ApiService.getAdGroups(payload)
        .then((data: MultiselectItem[]) => {
          commit("setAdGroups", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getAdTitles({ commit }, payload: CommonMultiselectPayload): Promise<AdTitlesByLeads[]> {
    return new Promise((resolve, reject) => {
      ApiService.getAdTitles(payload)
        .then((data: AdTitlesByLeads[]) => {
          commit("setAdTitles", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getAdditionalStatuses({ commit }, payload: CommonMultiselectPayload): Promise<SelectListItem[]> {
    return new Promise((resolve, reject) => {
      ApiService.getAdditionalStatuses(payload)
        .then((data: SelectListItem[]) => {
          commit("setAdditionalStatuses", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getAssets({ commit }, payload: CommonMultiselectPayload): Promise<ClientProject[]> {
    return new Promise((resolve, reject) => {
      ApiService.getAssets(payload)
        .then((data: ClientProject[]) => {
          commit("setAssets", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getAssignees({ commit }, payload: CommonMultiselectPayload): Promise<Assignee[]> {
    return new Promise((resolve, reject) => {
      ApiService.getAssignees(payload)
        .then((data: Assignee[]) => {
          commit("setAssignees", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getChannels({ commit }, payload: CommonMultiselectPayload): Promise<MultiselectItem[]> {
    commit("setChannels", []);
    return new Promise((resolve, reject) => {
      ApiService.getChannels(payload)
        .then((data: MultiselectItem[]) => {
          commit("setChannels", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getClients({ commit }): Promise<MultiselectItem[]> {
    return new Promise((resolve, reject) => {
      ApiService.getClients()
        .then((data: MultiselectItem[]) => {
          commit("setClients", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getCommitments({ commit }, payload: CommonMultiselectPayload): Promise<MultiselectItem[]> {
    return new Promise((resolve, reject) => {
      ApiService.getCommitments(payload)
        .then((data: MultiselectItem[]) => {
          commit("setCommitments", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getLeads({ commit }, payload: LeadsPayload): Promise<ContactForm[]> {
    commit("setLeads", null);
    return new Promise((resolve, reject) => {
      ApiService.getLeads(payload)
        .then((data: ContactForm[]) => {
          commit("setLeads", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getLeadsStatisticByAdditionalStatus({ commit }, payload: LeadsPayload): Promise<LeadStatistic[]> {
    commit("setLeadsStatisticByAdditionalStatus", null);
    return new Promise((resolve, reject) => {
      ApiService.getLeadsStatisticByAdditionalStatus(payload)
        .then((data: LeadStatistic[]) => {
          commit("setLeadsStatisticByAdditionalStatus", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getLeadsStatisticByCallStatus({ commit }, payload: LeadsPayload): Promise<LeadStatistic[]> {
    commit("setLeadsStatisticByCallStatus", null);
    return new Promise((resolve, reject) => {
      ApiService.getLeadsStatisticByCallStatus(payload)
        .then((data: LeadStatistic[]) => {
          commit("setLeadsStatisticByCallStatus", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getLeadsStatisticByCampaigns({ commit }, payload: LeadsStatisticByCampaignsPayload): Promise<LeadStatistic[]> {
    commit("setLeadsStatisticByCampaigns", null);
    return new Promise((resolve, reject) => {
      ApiService.getLeadsStatisticByCampaigns(payload)
        .then((data: LeadStatistic[]) => {
          commit("setLeadsStatisticByCampaigns", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getLeadsStatisticByPhoneAndForms({ commit }, payload: LeadsPayload): Promise<LeadStatistic[]> {
    commit("setLeadsStatisticByPhoneAndForms", null);
    return new Promise((resolve, reject) => {
      ApiService.getLeadsStatisticByPhoneAndForms(payload)
        .then((data: LeadStatistic[]) => {
          commit("setLeadsStatisticByPhoneAndForms", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getLeadsStatisticBySaleStatus({ commit }, payload: LeadsPayload): Promise<LeadStatistic[]> {
    commit("setLeadsStatisticBySaleStatus", null);
    return new Promise((resolve, reject) => {
      ApiService.getLeadsStatisticBySaleStatus(payload)
        .then((data: LeadStatistic[]) => {
          commit("setLeadsStatisticBySaleStatus", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getLeadsStatisticChannelMetadataType({ commit }, payload: LeadsPayload): Promise<LeadStatistic[]> {
    commit("setLeadsStatisticChannelMetadataType", null);
    return new Promise((resolve, reject) => {
      ApiService.getLeadsStatisticChannelMetadataType(payload)
        .then((data: LeadStatistic[]) => {
          commit("setLeadsStatisticChannelMetadataType", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getLeadsStatisticDealMeetingsLeads({ commit }, payload: LeadsPayload): Promise<LeadStatistic[]> {
    commit("setLeadsStatisticDealMeetingsLeads", null);
    return new Promise((resolve, reject) => {
      ApiService.getLeadsStatisticDealMeetingsLeads(payload)
        .then((data: LeadStatistic[]) => {
          commit("setLeadsStatisticDealMeetingsLeads", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setDatePeriod({ commit }, datePeriod: Date[]): void {
    commit("setDatePeriod", datePeriod);
  },

  setSelectedAdditionalStatusesIds({ commit }, selectedAdditionalStatusesIds: number[]): void {
    commit("setSelectedAdditionalStatusesIds", selectedAdditionalStatusesIds);
  },

  setSelectedAdGroupsIds({ commit }, selectedAdGroupsIds: number[]): void {
    commit("setSelectedAdGroupsIds", selectedAdGroupsIds);
  },

  setSelectedAdTitles({ commit }, selectedAdTitles: string[]): void {
    commit("setSelectedAdTitles", selectedAdTitles);
  },

  setSelectedAssetsIds({ commit }, selectedAssetsIds: string[]): void {
    commit("setSelectedAssetsIds", selectedAssetsIds);
  },

  setSelectedAssigneesIds({ commit }, selectedAssigneesIds: number[]): void {
    commit("setSelectedAssigneesIds", selectedAssigneesIds);
  },

  setSelectedCallStatusesIds({ commit }, selectedCallStatusesIds: MultiselectItem[]): void {
    commit("setSelectedCallStatusesIds", selectedCallStatusesIds);
  },

  setSelectedChannelMetadataTypesIds({ commit }, selectedChannelMetadataTypesIds: number[]): void {
    commit("setSelectedChannelMetadataTypesIds", selectedChannelMetadataTypesIds);
  },

  setSelectedChannelsIds({ commit }, selectedChannelsIds: number[]): void {
    commit("setSelectedChannelsIds", selectedChannelsIds);
  },

  setSelectedClientsIds({ commit }, selectedClientsIds: number[]): void {
    commit("setSelectedClientsIds", selectedClientsIds);
  },

  setSelectedCommitmentsIds({ commit }, selectedCommitmentsIds: number[]): void {
    commit("setSelectedCommitmentsIds", selectedCommitmentsIds);
  },

  setSelectedLeadStatusesIds({ commit }, selectedLeadStatusesIds: LeadStatus[]): void {
    commit("setSelectedLeadStatusesIds", selectedLeadStatusesIds);
  },

  setSelectedSaleStatusesIds({ commit }, selectedSaleStatusesIds: LeadStatus[]): void {
    commit("setSelectedSaleStatusesIds", selectedSaleStatusesIds);
  },

  setSelectedTags({ commit }, selectedTags: string[]): void {
    commit("setSelectedTags", selectedTags);
  },

  setShowDeleted({ commit }, showDeleted: boolean): void {
    commit("setShowDeleted", showDeleted);
  },

  setShowFavourite({ commit }, showFavourite: boolean): void {
    commit("setShowFavourite", showFavourite);
  },

  setShowNotActiveCampaign({ commit }, showNotActiveCampaign: boolean): void {
    commit("setShowNotActiveCampaign", showNotActiveCampaign);
  },

  setUniqueByCampaign({ commit }, uniqueByCampaign: boolean): void {
    commit("setUniqueByCampaign", uniqueByCampaign);
  },

  setTags({ commit }, tags: MultiselectItem[]): void {
    commit("setTags", tags);
  },
};
export default actions;
