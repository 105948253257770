
import { ChartOptions } from "chart.js";
import { Doughnut, mixins } from "vue-chartjs";
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component({
  extends: Doughnut,
  mixins: [mixins.reactiveProp],
})
export default class SmartphonesGraphDoughnut extends Mixins(mixins.reactiveProp, Doughnut) {
  @Prop() readonly options!: ChartOptions;

  mounted(): void {
    this.renderChart(this.chartData, this.options);
  }
}
