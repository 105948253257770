import { render, staticRenderFns } from "./ChannelsInnerDemo.vue?vue&type=template&id=ee7d7afa&scoped=true&"
import script from "./ChannelsInnerDemo.vue?vue&type=script&lang=ts&"
export * from "./ChannelsInnerDemo.vue?vue&type=script&lang=ts&"
import style0 from "./ChannelsInnerDemo.vue?vue&type=style&index=0&id=ee7d7afa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee7d7afa",
  null
  
)

export default component.exports