
import { ChartOptions } from "chart.js";
import { Line, mixins } from "vue-chartjs";
import { Component, Mixins } from "vue-property-decorator";
import "chartjs-plugin-datalabels";

@Component({
  extends: Line,
  mixins: [mixins.reactiveProp],
})
export default class ChannelsGraphLine extends Mixins(mixins.reactiveProp, Line) {
  options: ChartOptions = {
    legend: {
      display: false,
    },
    layout: {
      padding: 0,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            suggestedMin: 0,
            suggestedMax: 200,
          },
        },
      ],
      xAxes: [
        {
          type: "time",
          time: {
            unit: "day",
            displayFormats: {
              day: "DD/MM/YYYY",
            },
          },
        },
      ],
    },
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 0,
      },
    },
    animation: {
      duration: 0,
    },
    hover: {
      animationDuration: 200,
    },
    responsiveAnimationDuration: 0,
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  mounted(): void {
    this.renderChart(this.chartData, this.options);
  }
}
