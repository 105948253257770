import axios, { AxiosRequestConfig } from "axios";
import ConfigService from "./ConfigService";
import { ContactForm } from "@/models/ContactForm";
import { ClientProject } from "@/models/ClientProject";
import { LeadStatistic } from "@/models/LeadStatistic";
import { LeadsGridColumn, LeadsGridColumnExportSetting } from "@/models/LeadsGridColumn";
import { Result } from "@/models/Result";
import { UserData } from "@/models/UserData";
import { AdTitlesByLeads, Assignee, MultiselectItem, SelectListItem } from "@/models/MultiselectItem";
import HelperService from "./HelperService";

const requestConfig: AxiosRequestConfig = {
  withCredentials: true,
};

export default {
  exportLeadsToExcel(leads: ContactForm[], columns: LeadsGridColumn[]): Promise<string> {
    const columnsToExport: LeadsGridColumnExportSetting[] = columns.map((i: LeadsGridColumn) => {
      return {
        Field: i.name,
        Width: i.width ? i.width.toString() : "",
        Hidden: !i.visible,
      };
    });
    return new Promise((resolve, reject) => {
      const url = new URL(ConfigService.exportLeadsToExcelUrl);
      axios
        .post(url.toString(), { Columns: columnsToExport, Leads: JSON.parse(JSON.stringify(leads)).Data }, requestConfig)
        .then((response) => {
          const result: Result = response.data;
          if (result.Success) {
            resolve(result.Data);
          } else {
            reject(result.Message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getAdGroups(payload: CommonMultiselectPayload): Promise<MultiselectItem[]> {
    return new Promise((resolve, reject) => {
      const url = new URL(ConfigService.adGroupsUrl);
      url.searchParams.append("clientIds", JSON.stringify(payload.clientIds));
      url.searchParams.append("showNotActiveCamp", payload.showNotActiveCamp.toString());
      url.searchParams.append("metadataIds", JSON.stringify(payload.metadataIds));
      url.searchParams.append("statusIds", JSON.stringify(payload.statusIds));
      url.searchParams.append("channelIds", JSON.stringify(payload.channelIds));
      axios
        .post(url.toString(), null, requestConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getAdTitles(payload: CommonMultiselectPayload): Promise<AdTitlesByLeads[]> {
    return new Promise((resolve, reject) => {
      const url = new URL(ConfigService.adTitlesUrl);
      url.searchParams.append("clientIds", JSON.stringify(payload.clientIds));
      url.searchParams.append("showNotActiveCamp", payload.showNotActiveCamp.toString());
      url.searchParams.append("metadataIds", JSON.stringify(payload.metadataIds));
      url.searchParams.append("statusIds", JSON.stringify(payload.statusIds));
      url.searchParams.append("channelIds", JSON.stringify(payload.channelIds));
      axios
        .post(url.toString(), null, requestConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getAdditionalStatuses(payload: CommonMultiselectPayload): Promise<SelectListItem[]> {
    return new Promise((resolve, reject) => {
      const url = new URL(ConfigService.additionalStatusesUrl);
      url.searchParams.append("clientIds", JSON.stringify(payload.clientIds));
      url.searchParams.append("showNotActiveCamp", payload.showNotActiveCamp.toString());
      url.searchParams.append("metadataIds", JSON.stringify(payload.metadataIds));
      url.searchParams.append("statusIds", JSON.stringify(payload.statusIds));
      url.searchParams.append("channelIds", JSON.stringify(payload.channelIds));
      axios
        .post(url.toString(), null, requestConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getAssets(payload: CommonMultiselectPayload): Promise<ClientProject[]> {
    return new Promise((resolve, reject) => {
      const url = new URL(ConfigService.assetsUrl);
      url.searchParams.append("clientIds", JSON.stringify(payload.clientIds));
      url.searchParams.append("showNotActiveCamp", payload.showNotActiveCamp.toString());
      url.searchParams.append("metadataIds", JSON.stringify(payload.metadataIds));
      url.searchParams.append("statusIds", JSON.stringify(payload.statusIds));
      url.searchParams.append("channelIds", JSON.stringify(payload.channelIds));
      axios
        .post(url.toString(), null, requestConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getAssignees(payload: CommonMultiselectPayload): Promise<Assignee[]> {
    return new Promise((resolve, reject) => {
      const url = new URL(ConfigService.assigneesUrl);
      url.searchParams.append("clientIds", JSON.stringify(payload.clientIds));
      url.searchParams.append("showNotActiveCamp", payload.showNotActiveCamp.toString());
      url.searchParams.append("metadataIds", JSON.stringify(payload.metadataIds));
      url.searchParams.append("statusIds", JSON.stringify(payload.statusIds));
      url.searchParams.append("channelIds", JSON.stringify(payload.channelIds));
      axios
        .post(url.toString(), null, requestConfig)
        .then((response) => {
          resolve(response.data.Data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getChannels(payload: CommonMultiselectPayload): Promise<MultiselectItem[]> {
    return new Promise((resolve, reject) => {
      const url = new URL(ConfigService.channelsUrl);
      url.searchParams.append("clientIds", JSON.stringify(payload.clientIds));
      url.searchParams.append("showNotActiveCamp", payload.showNotActiveCamp.toString());
      url.searchParams.append("metadataIds", JSON.stringify(payload.metadataIds));
      url.searchParams.append("statusIds", JSON.stringify(payload.statusIds));
      url.searchParams.append("channelIds", JSON.stringify(payload.channelIds));
      axios
        .post(url.toString(), null, requestConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getClients(): Promise<MultiselectItem[]> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${ConfigService.clientsUrl}?addShared=True`, null, requestConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getCommitments(payload: CommonMultiselectPayload): Promise<MultiselectItem[]> {
    return new Promise((resolve, reject) => {
      const url = new URL(ConfigService.commitmentsUrl);
      url.searchParams.append("clientIds", JSON.stringify(payload.clientIds));
      url.searchParams.append("showNotActiveCamp", payload.showNotActiveCamp.toString());
      url.searchParams.append("metadataIds", JSON.stringify(payload.metadataIds));
      url.searchParams.append("statusIds", JSON.stringify(payload.statusIds));
      url.searchParams.append("channelIds", JSON.stringify(payload.channelIds));
      axios
        .post(url.toString(), null, requestConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getDataWithLeadsPayload(payload: LeadsPayload, urlString: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const url = new URL(urlString);
      if (payload.clientId) {
        url.searchParams.append("сlientId", payload.clientId.toString());
      }
      if (payload.dateFrom) {
        url.searchParams.append("DateFrom", payload.dateFrom.toString());
      }
      if (payload.datePeriod) {
        url.searchParams.append("DatePeriod", payload.datePeriod);
      }
      if (payload.dateTo) {
        url.searchParams.append("DateTo", payload.dateTo.toString());
      }
      if (payload.showDeleted !== undefined) {
        url.searchParams.append("showDeleted", payload.showDeleted ? "true" : "false");
      }
      if (payload.filter) {
        url.searchParams.append("filter", payload.filter);
      }
      if (payload.group) {
        url.searchParams.append("group", payload.group);
      }
      if (payload.page) {
        url.searchParams.append("page", payload.page.toString());
      }
      if (payload.pageSize) {
        url.searchParams.append("pageSize", payload.pageSize.toString());
      }
      if (payload.priceType) {
        url.searchParams.append("priceType", payload.priceType);
      }
      if (payload.sort) {
        url.searchParams.append("sort", payload.sort);
      }
      if (payload.token) {
        url.searchParams.append("token", payload.token);
      }
      if (payload.unique !== undefined) {
        url.searchParams.append("unique", payload.unique ? "true" : "false");
      }

      axios
        .post(url.toString(), null, requestConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getDataWithLeadsStatisticByCampaignsPayload(payload: LeadsStatisticByCampaignsPayload, urlString: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const url = new URL(urlString);
      if (payload.clientId) {
        url.searchParams.append("сlientId", payload.clientId.toString());
      }
      if (payload.clientSelected) {
        url.searchParams.append("clientSelected", payload.clientSelected.toString());
      }
      if (payload.dateFrom) {
        url.searchParams.append("dateFrom", payload.dateFrom.toString());
      }
      if (payload.dateTo) {
        url.searchParams.append("dateTo", payload.dateTo.toString());
      }
      if (payload.filter) {
        url.searchParams.append("filter", payload.filter);
      }
      if (payload.token) {
        url.searchParams.append("token", payload.token);
      }

      axios
        .post(url.toString(), null, requestConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getLeads(payload: LeadsPayload): Promise<ContactForm[]> {
    return this.getDataWithLeadsPayload(payload, ConfigService.contactFormsUrl);
  },

  getLeadsStatisticByAdditionalStatus(payload: LeadsPayload): Promise<LeadStatistic[]> {
    return this.getDataWithLeadsPayload(payload, ConfigService.leadsStatisticByAdditionalStatusUrl);
  },

  getLeadsStatisticByCallStatus(payload: LeadsPayload): Promise<LeadStatistic[]> {
    return this.getDataWithLeadsPayload(payload, ConfigService.leadsStatisticByCallStatusUrl);
  },

  getLeadsStatisticByCampaigns(payload: LeadsStatisticByCampaignsPayload): Promise<LeadStatistic[]> {
    return this.getDataWithLeadsStatisticByCampaignsPayload(payload, ConfigService.leadsStatisticByCampaignsUrl);
  },

  getLeadsStatisticByPhoneAndForms(payload: LeadsPayload): Promise<LeadStatistic[]> {
    return this.getDataWithLeadsPayload(payload, ConfigService.leadsStatisticByPhoneAndFormsUrl);
  },

  getLeadsStatisticBySaleStatus(payload: LeadsPayload): Promise<LeadStatistic[]> {
    return this.getDataWithLeadsPayload(payload, ConfigService.leadsStatisticBySaleStatusUrl);
  },

  getLeadsStatisticChannelMetadataType(payload: LeadsPayload): Promise<LeadStatistic[]> {
    return this.getDataWithLeadsPayload(payload, ConfigService.leadsStatisticChannelMetadataTypeUrl);
  },

  getLeadsStatisticDealMeetingsLeads(payload: LeadsPayload): Promise<LeadStatistic[]> {
    return this.getDataWithLeadsPayload(payload, ConfigService.leadsStatisticDealMeetingsLeadsUrl);
  },

  getUserData(): Promise<UserData> {
    return new Promise((resolve, reject) => {
      const url = new URL(ConfigService.userDataUrl);
      axios
        .post(url.toString(), null, requestConfig)
        .then((response) => {
          // if user not authenticated and returned login page html, redirect to login
          if (typeof response.data === "string" && response.data.indexOf("/login") >= 0) {
            location.href = `${ConfigService.loginUrl}?returnUrl=${location.pathname}`;
          }
          const result: Result = response.data;
          if (result.Success) {
            resolve(result.Data);
          } else {
            reject(result.Message);
          }
        })
        .catch((error) => {
          reject(error);
          location.href = `${ConfigService.loginUrl}?returnUrl=${location.pathname}`;
        });
    });
  },

  logout(): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .get(ConfigService.logoutUrl, requestConfig)
        .then((response) => {
          const result: Result = response.data;
          if (result.Success) {
            resolve();
          } else {
            HelperService.showError(result.Message);
            reject(result.Message);
          }
        })
        .catch((error) => {
          HelperService.showError(error);
          reject(error);
        });
    });
  },
};

export interface CommonMultiselectPayload {
  clientIds: number[];
  showNotActiveCamp: boolean;
  metadataIds: number[];
  statusIds: string[];
  channelIds: number[];
}

export interface LeadsPayload {
  clientId?: number;
  dateFrom?: string;
  datePeriod?: string;
  dateTo?: string;
  filter?: string;
  group?: string;
  page?: number;
  pageSize: number;
  priceType?: string;
  showDeleted?: boolean;
  sort?: string;
  token?: string;
  unique?: boolean;
}

export interface LeadsStatisticByCampaignsPayload {
  clientId?: number;
  clientSelected?: boolean;
  dateFrom?: string;
  dateTo?: string;
  filter?: string;
  token?: string;
}
