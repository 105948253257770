






















































































import moment from "moment";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { ChannelMetadataType } from "@/models/ChannelMetadataType";
import { ContactForm } from "@/models/ContactForm";
import IconQuestionCircle from "../icons/IconQuestionCircle.vue";
import i18n from "../../i18n";
import IconBase from "../../components/IconBase.vue";
import IconPencil from "../../components/icons/IconPencil.vue";
import IconEye from "../../components/icons/IconEye.vue";
import IconLink from "../../components/icons/IconLink.vue";
import IconRefer from "../../components/icons/IconRefer.vue";
import IconPlayCircle from "../../components/icons/IconPlayCircle.vue";
import IconTimesCircle from "../../components/icons/IconTimesCircle.vue";
import { LeadsGridColumn } from "../../models/LeadsGridColumn";

const namespace = "leadsModule";

@Component({
  components: {
    IconBase,
    IconPencil,
    IconEye,
    IconLink,
    IconRefer,
    IconPlayCircle,
    IconQuestionCircle,
    IconTimesCircle,
  },
})
export default class LeadsGridContent extends Vue {
  @Getter("leads", { namespace }) leads!: ContactForm[];
  @Prop({ default: [] }) readonly columns!: Array<LeadsGridColumn>;

  ChannelMetadataType = ChannelMetadataType;

  getFormattedValue(value: number | string | Date, fieldName: string): string {
    if (!value) {
      return "";
    }
    if (fieldName === "CreateDate" || fieldName === "MeetingStartDate") {
      return moment(value as Date).format("DD/MM/yyyy HH:mm");
    } else if (fieldName === "Price") {
      return i18n.locale === "he" ? `₪${Math.ceil(value as number)}` : `${Math.ceil(value as number)}₪`;
    } else {
      return value as string;
    }
  }
}
