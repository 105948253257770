







import { Component, Vue, Watch } from "vue-property-decorator";
import Header from "./components/Header/Header.vue";
import { setDocumentDirectionPerLocale, setDocumentLang } from "@/services/I18n";

@Component({
  components: {
    Header,
  },
})
export default class App extends Vue {
  @Watch("$i18n.locale", { immediate: true })
  localeChanged(newLocale: string, oldLocale: string): void {
    if (newLocale === oldLocale) {
      return;
    }
    setDocumentLang(newLocale);
    setDocumentDirectionPerLocale(newLocale);
  }
}
