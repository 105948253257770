













import { Component, Vue } from "vue-property-decorator";
import LeadsHeadline from "../components/Leads/LeadsHeadline.vue";
import LeadsFilters from "../components/Leads/LeadsFilters.vue";
import LeadsGraphs from "../components/Leads/LeadsGraphs.vue";
import LeadsGrid from "../components/Leads/LeadsGrid.vue";
import VueMq from "vue-mq";

Vue.use(VueMq, {
  breakpoints: {
    sm: 576,
    md: 992,
    lg: Infinity,
  },
  defaultBreakpoint: "sm",
});

@Component({
  components: {
    LeadsHeadline,
    LeadsFilters,
    LeadsGraphs,
    LeadsGrid,
  },
})
export default class Leads extends Vue {
  public leadsGraphsVisible = true;

  public toggleGraphs(): void {
    this.leadsGraphsVisible = !this.leadsGraphsVisible;
  }
}
