




























































































































































































import { Component, Vue } from "vue-property-decorator";
import IconBase from "../components/IconBase.vue";
import IconCheckCircle from "../components/icons/IconCheckCircle.vue";
import IconCaretDown from "../components/icons/IconCaretDown.vue";
import IconChannel from "../components/icons/IconChannel.vue";
import IconGoal from "../components/icons/IconGoal.vue";
import IconWallet from "../components/icons/IconWallet.vue";
import IconConnection from "../components/icons/IconConnection.vue";
import IconSmartphone from "../components/icons/IconSmartphone.vue";
import IconProject from "../components/icons/IconProject.vue";
import IconTag from "../components/icons/IconTag.vue";
import IconArrowLeft from "../components/icons/IconArrowLeft.vue";
import VueMq from "vue-mq";

Vue.use(VueMq, {
  breakpoints: {
    sm: 576,
    md: 992,
    lg: Infinity,
  },
  defaultBreakpoint: "sm",
});

@Component({
  components: {
    IconBase,
    IconCheckCircle,
    IconCaretDown,
    IconChannel,
    IconGoal,
    IconWallet,
    IconConnection,
    IconSmartphone,
    IconProject,
    IconTag,
    IconArrowLeft,
  },
})
export default class Channels extends Vue {
  channelEditTab = 0;

  $mq: unknown;

  mounted(): void {
    this.updateChannelEditTab();
  }

  updateChannelEditTab(): void {
    this.channelEditTab = this.$mq == "lg" ? 1 : 0;
  }

  CustomerNameCurrent = { text: "דמו פיקס", label: "שם לקוח", disabled: true };
  CustomerNameOptions = [
    { value: "option1", text: "1" },
    { value: "option2", text: "2" },
    { value: "option3", text: "3" },
    { value: "option4", text: "4" },
  ];
  AdvertisingChannelCurrent = { text: "Google ads", label: "אפיק פרסומי", disabled: true };
  AdvertisingChannelOptions = [
    { value: "option1", text: "1" },
    { value: "option2", text: "2" },
    { value: "option3", text: "3" },
    { value: "option4", text: "4" },
  ];
  DigitalPropertyTypeCurrent = { text: "אתר חיצוני", label: "סוג נכס דיגיטלי", disabled: true };
  DigitalPropertyTypeOptions = [
    { value: "option1", text: "1" },
    { value: "option2", text: "2" },
    { value: "option3", text: "3" },
    { value: "option4", text: "4" },
  ];
  WebsiteCurrent = { text: "www.israel.com", label: "כתובת URL של אתר/דף נחיתה", disabled: true };
  WebsiteOptions = [
    { value: "option1", text: "1" },
    { value: "option2", text: "2" },
    { value: "option3", text: "3" },
    { value: "option4", text: "4" },
  ];
}
