import { MutationTree } from "vuex";
import { UserData } from "@/models/UserData";
import { MainState } from "@/models/states/MainState";

const mutations: MutationTree<MainState> = {
  setUserData(state: MainState, userData: UserData) {
    state.userData = userData;
  },
};
export default mutations;
