















































import VueMq from "vue-mq";
import { Component, Vue } from "vue-property-decorator";
import ChannelsGridHeadline from "./ChannelsGridHeadline.vue";
import ChannelsGridContent from "./ChannelsGridContent.vue";
import IconBase from "../IconBase.vue";
import IconSearch from "../icons/IconSearch.vue";
import IconCaretDown from "../icons/IconCaretDown.vue";
import IconCaretRight from "../icons/IconCaretRight.vue";
import IconCaretLeft from "../icons/IconCaretLeft.vue";

Vue.use(VueMq, {
  breakpoints: {
    sm: 576,
    md: 992,
    lg: Infinity,
  },
  defaultBreakpoint: "sm",
});

@Component({
  components: {
    ChannelsGridHeadline,
    ChannelsGridContent,
    IconBase,
    IconSearch,
    IconCaretDown,
    IconCaretRight,
    IconCaretLeft,
  },
})
export default class ChannelsGrid extends Vue {}
