

























































































import { Component, Vue } from "vue-property-decorator";
import VueCookies from "vue-cookies";
import Fragment from "vue-fragment";
import IconBase from "../IconBase.vue";
import IconCaretDown from "../icons/IconCaretDown.vue";

Vue.use(Fragment.Plugin);
Vue.use(VueCookies);

@Component({
  components: {
    IconBase,
    IconCaretDown,
  },
})
export default class SmartphonesFiltersDropdowns extends Vue {
  SmartphonesFilter1Selected = [];
  SmartphonesFilter1Height = 200;
  SmartphonesFilter1Current = { text: "שם לקוח: נבחרו" };
  SmartphonesFilter1Options = [
    { value: "option1", text: "1" },
    { value: "option2", text: "2" },
    { value: "option3", text: "3" },
    { value: "option4", text: "4" },
  ];
  SmartphonesFilter2Current = { text: "סטטוס" };
  SmartphonesFilter2Options = [
    { status: 0, text: "הכל" },
    { status: 1, text: "פעיל" },
    { status: 2, text: "משוחרר" },
  ];

  SmartphonesFilter3Selected = [];
  SmartphonesFilter3Current = { text: "אפיק פרסומי" };
  SmartphonesFilter3Options = [
    { logo: "google-ads", text: "Google ads" },
    { logo: "facebook", text: "Facebook ads" },
    { logo: "facebook", text: "Facebook leads" },
    { logo: "instagram", text: "Instagram ads" },
    { logo: "instagram", text: "Instagram leads" },
    { logo: "phone", text: "Phone" },
    { logo: "fixdigital", text: "Referral" },
  ];

  SmartphonesFilter4Current = { text: "סוג שיחה" };
  SmartphonesFilter4Options = [
    { value: "option1", text: "1" },
    { value: "option2", text: "2" },
    { value: "option3", text: "3" },
  ];

  SmartphonesFilter5Current = { text: "פרויקט" };
  SmartphonesFilter5Options = [
    { value: "option1", text: "1" },
    { value: "option2", text: "2" },
    { value: "option3", text: "3" },
  ];
}
