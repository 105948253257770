import i18n from "@/i18n";
import { AvailableLeadStatuses, AvailableSaleStatuses, LeadStatus } from "@/models/LeadStatus";
import { MetadataMaskyooStatus } from "@/models/MetadataMaskyooStatus";
import { MultiselectItem } from "@/models/MultiselectItem";

export default {
  getAvailableLeadStatuses(filter?: string): MultiselectItem[] {
    const result: MultiselectItem[] = [];
    for (const leadStatus of AvailableLeadStatuses) {
      const title: string = i18n.t(`Enums.LeadStatus.${LeadStatus[leadStatus]}`).toString();
      if (!filter || title.toLowerCase().indexOf(filter.toLowerCase()) >= 0) {
        result.push({
          ID: leadStatus.toString(),
          Title: title,
        });
      }
    }
    return result;
  },

  getAvailableSaleStatuses(filter?: string): MultiselectItem[] {
    const result: MultiselectItem[] = [];
    for (const saleStatus of AvailableSaleStatuses) {
      const title: string = i18n.t(`Enums.LeadStatus.${LeadStatus[saleStatus]}`).toString();
      if (!filter || title.toLowerCase().indexOf(filter.toLowerCase()) >= 0) {
        result.push({
          ID: saleStatus.toString(),
          Title: title,
        });
      }
    }
    return result;
  },

  getCallStatuses(): MultiselectItem[] {
    return [
      {
        ID: "-2",
        Title: i18n.t("Enums.MetadataMaskyooStatus.Forms").toString(),
      },
      {
        ID: "-1",
        Title: i18n.t("Enums.MetadataMaskyooStatus.All").toString(),
      },
      {
        ID: MetadataMaskyooStatus.Answer.toString(),
        Title: i18n.t(`Enums.MetadataMaskyooStatus.${MetadataMaskyooStatus[MetadataMaskyooStatus.Answer]}`).toString(),
      },
      {
        ID: MetadataMaskyooStatus.NotAnswer.toString(),
        Title: i18n.t(`Enums.MetadataMaskyooStatus.${MetadataMaskyooStatus[MetadataMaskyooStatus.NotAnswer]}`).toString(),
      },
      {
        ID: MetadataMaskyooStatus.Callback.toString(),
        Title: i18n.t(`Enums.MetadataMaskyooStatus.${MetadataMaskyooStatus[MetadataMaskyooStatus.Callback]}`).toString(),
      },
      {
        ID: "-3",
        Title: i18n.t("Enums.MetadataMaskyooStatus.WhatsApp").toString(),
      },
    ];
  },
};
