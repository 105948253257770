






















































































































































import { Component, Vue } from "vue-property-decorator";
import IconBase from "../../components/IconBase.vue";
import IconPencil from "../../components/icons/IconPencil.vue";
import IconBell from "../../components/icons/IconBell.vue";
import IconHistory from "../../components/icons/IconHistory.vue";
import IconMobileYes from "../../components/icons/IconMobileYes.vue";
import IconMobileNo from "../../components/icons/IconMobileNo.vue";
import IconUpdate from "../../components/icons/IconUpdate.vue";

@Component({
  components: {
    IconBase,
    IconPencil,
    IconBell,
    IconHistory,
    IconMobileYes,
    IconMobileNo,
    IconUpdate,
  },
})
export default class ChannelsGridContent extends Vue {
  connectionAction = true;
  smartphoneOn = true;
  data = [
    {
      id: "232323",
      channel_name: "גוגל אדס 1",
      channel_name_logo: "google-ads",
      advertising_channel: "Google ads",
      customer_name: "דמו פיקס",
      digital_property: "דמו פיקס 1",
      digital_asset_type: "דף נחיתה שנבנה במערכת...",
      tagged_url: "https://lpc.fixdigital.co.il",
      status: 1,
      budget: "",
      expected_quantity: "",
      price_approx: "",
      сonversion_rate: "0%",
      actions: [{}],
      ad_keywords: "דירה ברעננה",
    },
    {
      id: "232324",
      channel_name: "גוגל אדס 1",
      channel_name_logo: "facebook",
      advertising_channel: "Google ads",
      customer_name: "דמו פיקס",
      digital_property: "דמו פיקס 1",
      digital_asset_type: "דף נחיתה שנבנה במערכת...",
      tagged_url: "https://lpc.fixdigital.co.il",
      status: 2,
      budget: "",
      expected_quantity: "",
      price_approx: "",
      сonversion_rate: "0%",
      actions: [{}],
      ad_keywords: "+ דירות + ברעננה",
    },
    {
      id: "232325",
      channel_name: "גוגל אדס 1",
      channel_name_logo: "phone",
      advertising_channel: "Google ads",
      customer_name: "דמו פיקס",
      digital_property: "דמו פיקס 1",
      digital_asset_type: "דף נחיתה שנבנה במערכת...",
      tagged_url: "https://lpc.fixdigital.co.il",
      status: 3,
      budget: "",
      expected_quantity: "",
      price_approx: "",
      сonversion_rate: "0%",
      actions: [{}],
      ad_keywords: "דירה ברעננה",
    },
    {
      id: "232326",
      channel_name: "גוגל אדס 1",
      channel_name_logo: "fixdigital",
      advertising_channel: "Google ads",
      customer_name: "דמו פיקס",
      digital_property: "דמו פיקס 1",
      digital_asset_type: "דף נחיתה שנבנה במערכת...",
      tagged_url: "https://lpc.fixdigital.co.il",
      status: 4,
      budget: "",
      expected_quantity: "",
      price_approx: "",
      сonversion_rate: "0%",
      actions: [{}],
      ad_keywords: "+ דירות + ברעננה",
    },
  ];

  getSrcset(pathM: string, path: string): string {
    return pathM + " 768w, " + path;
  }
}
