











































































































































































































































import moment from "moment";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import VueCookies from "vue-cookies";
import Fragment from "vue-fragment";
import { ChannelMetadataType } from "@/models/ChannelMetadataType";
import { ClientProject } from "@/models/ClientProject";
import { ContactForm } from "@/models/ContactForm";
import { LeadPriceType } from "@/models/LeadPriceType";
import { LeadStatistic, LeadStatisticExtended } from "@/models/LeadStatistic";
import { LeadStatus } from "@/models/LeadStatus";
import { AdTitlesByLeads, Assignee, MultiselectItem, SelectListItem } from "@/models/MultiselectItem";
import { CommonMultiselectPayload, LeadsPayload, LeadsStatisticByCampaignsPayload } from "@/services/ApiService";
import ChannelService from "@/services/ChannelService";
import LeadService from "@/services/LeadService";
import IconBase from "../IconBase.vue";
import IconCaretDown from "../icons/IconCaretDown.vue";
import i18n from "../../i18n";

Vue.use(Fragment.Plugin);
Vue.use(VueCookies);
const namespace = "leadsModule";

@Component({
  components: {
    IconBase,
    IconCaretDown,
  },
})
export default class LeadsFiltersDropdowns extends Vue {
  //#region Vuex getters and actions

  @Getter("adGroups", { namespace }) adGroups!: MultiselectItem[];
  @Getter("adTitles", { namespace }) adTitles!: AdTitlesByLeads[];
  @Getter("additionalStatuses", { namespace }) additionalStatuses!: SelectListItem[];
  @Getter("assets", { namespace }) assets!: ClientProject[];
  @Getter("assignees", { namespace }) assignees!: Assignee[];
  @Getter("channels", { namespace }) channels!: MultiselectItem[];
  @Getter("clients", { namespace }) clients!: MultiselectItem[];
  @Getter("commitments", { namespace }) commitments!: MultiselectItem[];
  @Getter("datePeriod", { namespace }) datePeriod!: Date[];
  @Getter("leads", { namespace }) leads!: ContactForm[];
  @Getter("selectedAdditionalStatusesIds", { namespace }) selectedAdditionalStatusesIds!: number[];
  @Getter("selectedAdGroupsIds", { namespace }) selectedAdGroupsIds!: number[];
  @Getter("selectedAdTitles", { namespace }) selectedAdTitles!: string[];
  @Getter("selectedAssetsIds", { namespace }) selectedAssetsIds!: string[];
  @Getter("selectedAssigneesIds", { namespace }) selectedAssigneesIds!: number[];
  @Getter("selectedCallStatusesIds", { namespace }) selectedCallStatusesIds!: number[];
  @Getter("selectedChannelMetadataTypesIds", { namespace }) selectedChannelMetadataTypesIds!: number[];
  @Getter("selectedChannelsIds", { namespace }) selectedChannelsIds!: number[];
  @Getter("selectedClientsIds", { namespace }) selectedClientsIds!: number[];
  @Getter("selectedCommitmentsIds", { namespace }) selectedCommitmentsIds!: number[];
  @Getter("selectedLeadStatusesIds", { namespace }) selectedLeadStatusesIds!: LeadStatus[];
  @Getter("selectedSaleStatusesIds", { namespace }) selectedSaleStatusesIds!: LeadStatus[];
  @Getter("selectedTags", { namespace }) selectedTags!: string[];
  @Getter("showDeleted", { namespace }) showDeleted!: boolean;
  @Getter("showFavourite", { namespace }) showFavourite!: boolean;
  @Getter("showNotActiveCampaign", { namespace }) showNotActiveCampaign!: boolean;
  @Getter("tags", { namespace }) tags!: MultiselectItem[];
  @Getter("uniqueByCampaign", { namespace }) uniqueByCampaign!: boolean;
  @Action("clearAdditionalStatuses", { namespace }) clearAdditionalStatuses!: () => void;
  @Action("clearCommitments", { namespace }) clearCommitments!: () => void;
  @Action("getAdGroups", { namespace }) getAdGroups!: (payload: CommonMultiselectPayload) => Promise<MultiselectItem[]>;
  @Action("getAdTitles", { namespace }) getAdTitles!: (payload: CommonMultiselectPayload) => Promise<AdTitlesByLeads[]>;
  @Action("getAdditionalStatuses", { namespace }) getAdditionalStatuses!: (payload: CommonMultiselectPayload) => Promise<SelectListItem[]>;
  @Action("getAssets", { namespace }) getAssets!: (payload: CommonMultiselectPayload) => Promise<ClientProject[]>;
  @Action("getAssignees", { namespace }) getAssignees!: (payload: CommonMultiselectPayload) => Promise<Assignee[]>;
  @Action("getChannels", { namespace }) getChannels!: (payload: CommonMultiselectPayload) => Promise<MultiselectItem[]>;
  @Action("getClients", { namespace }) getClients!: () => Promise<MultiselectItem[]>;
  @Action("getCommitments", { namespace }) getCommitments!: (payload: CommonMultiselectPayload) => Promise<MultiselectItem[]>;
  @Action("getLeads", { namespace }) getLeads!: (payload: LeadsPayload) => Promise<ContactForm[]>;
  @Action("getLeadsStatisticByAdditionalStatus", { namespace }) getLeadsStatisticByAdditionalStatus!: (payload: LeadsPayload) => Promise<LeadStatistic[]>;
  @Action("getLeadsStatisticByCallStatus", { namespace }) getLeadsStatisticByCallStatus!: (payload: LeadsPayload) => Promise<LeadStatistic[]>;
  @Action("getLeadsStatisticByCampaigns", { namespace }) getLeadsStatisticByCampaigns!: (payload: LeadsStatisticByCampaignsPayload) => Promise<LeadStatistic[]>;
  @Action("getLeadsStatisticByPhoneAndForms", { namespace }) getLeadsStatisticByPhoneAndForms!: (payload: LeadsPayload) => Promise<LeadStatistic[]>;
  @Action("getLeadsStatisticBySaleStatus", { namespace }) getLeadsStatisticBySaleStatus!: (payload: LeadsPayload) => Promise<LeadStatistic[]>;
  @Action("getLeadsStatisticChannelMetadataType", { namespace }) getLeadsStatisticChannelMetadataType!: (payload: LeadsPayload) => Promise<LeadStatistic[]>;
  @Action("getLeadsStatisticDealMeetingsLeads", { namespace }) getLeadsStatisticDealMeetingsLeads!: (payload: LeadsPayload) => Promise<LeadStatisticExtended[]>;
  @Action("setSelectedAdditionalStatusesIds", { namespace }) setSelectedAdditionalStatusesIds!: (selectedAdditionalStatusesIds: number[]) => void;
  @Action("setSelectedAdGroupsIds", { namespace }) setSelectedAdGroupsIds!: (selectedAdGroupsIds: number[]) => void;
  @Action("setSelectedAdTitles", { namespace }) setSelectedAdTitles!: (selectedAdTitles: string[]) => void;
  @Action("setSelectedAssetsIds", { namespace }) setSelectedAssetsIds!: (selectedAssetsIds: string[]) => void;
  @Action("setSelectedAssigneesIds", { namespace }) setSelectedAssigneesIds!: (selectedAssigneesIds: number[]) => void;
  @Action("setSelectedCallStatusesIds", { namespace }) setSelectedCallStatusesIds!: (selectedCallStatusesIds: number[]) => void;
  @Action("setSelectedChannelMetadataTypesIds", { namespace }) setSelectedChannelMetadataTypesIds!: (selectedChannelMetadataTypesIds: number[]) => void;
  @Action("setSelectedChannelsIds", { namespace }) setSelectedChannelsIds!: (selectedChannelsIds: number[]) => void;
  @Action("setSelectedClientsIds", { namespace }) setSelectedClientsIds!: (selectedClientsIds: number[]) => void;
  @Action("setSelectedCommitmentsIds", { namespace }) setSelectedCommitmentsIds!: (selectedCommitmentsIds: number[]) => void;
  @Action("setSelectedLeadStatusesIds", { namespace }) setSelectedLeadStatusesIds!: (selectedLeadStatusesIds: LeadStatus[]) => void;
  @Action("setSelectedSaleStatusesIds", { namespace }) setSelectedSaleStatusesIds!: (selectedSaleStatusesIds: LeadStatus[]) => void;
  @Action("setSelectedTags", { namespace }) setSelectedTags!: (selectedTags: string[]) => void;
  @Action("setTags", { namespace }) setTags!: (tags: MultiselectItem[]) => void;

  //#endregion Vuex getters and actions

  //#region Properties

  LeadPriceType = LeadPriceType;

  page = 1; // TODO
  pageSize = 30; // TODO
  sort = ""; // TODO

  adGroupsSearch = "";
  adTitlesSearch = "";
  additionalStatusesSearch = "";
  assetsSearch = "";
  assigneesSearch = "";
  channelMetadataTypesSearch = "";
  channelsSearch = "";
  clientsSearch = "";
  commitmentsSearch = "";
  leadStatusesSearch = "";
  saleStatusesSearch = "";

  selectedPriceType: string = this.priceTypes[0].ID;

  token = "";

  //#endregion Properties

  //#region Watchers

  @Watch("datePeriod", { immediate: true })
  onDatePeriodChange(newDatePeriod: Date[], oldDatePeriod: Date[]): void {
    if (oldDatePeriod === undefined || oldDatePeriod.length === 0) {
      return;
    } else {
      this.refreshGrid();
    }
  }

  @Watch("selectedAdditionalStatusesIds", { immediate: true })
  onSelectedAdditionalStatusesIdsChange(newSelectedAdditionalStatusesIds: number[], oldSelectedAdditionalStatusesIds: number[]): void {
    if (oldSelectedAdditionalStatusesIds === undefined || newSelectedAdditionalStatusesIds.length === oldSelectedAdditionalStatusesIds.length) {
      return;
    } else {
      this.refreshGrid();
    }
  }

  @Watch("selectedAdGroupsIds", { immediate: true })
  onSelectedAdGroupsIdsChange(newSelectedAdGroupsIds: number[], oldSelectedAdGroupsIds: number[]): void {
    if (oldSelectedAdGroupsIds === undefined || newSelectedAdGroupsIds.length === oldSelectedAdGroupsIds.length) {
      return;
    } else {
      this.refreshGrid();
    }
  }

  @Watch("selectedAdTitles", { immediate: true })
  onSelectedAdTitlesChange(newSelectedAdTitles: string[], oldSelectedAdTitles: string[]): void {
    if (oldSelectedAdTitles === undefined || newSelectedAdTitles.length === oldSelectedAdTitles.length) {
      return;
    } else {
      this.refreshGrid();
    }
  }

  @Watch("selectedAssetsIds", { immediate: true })
  onSelectedAssetsIdsChange(newSelectedAssetsIds: string[], oldSelectedAssetsIds: string[]): void {
    if (oldSelectedAssetsIds === undefined || newSelectedAssetsIds.length === oldSelectedAssetsIds.length) {
      return;
    } else {
      this.refreshGrid();
    }
  }

  @Watch("selectedAssigneesIds", { immediate: true })
  onSelectedAssigneesIdsChange(newSelectedAssigneesIds: number[], oldSelectedAssigneesIds: number[]): void {
    if (oldSelectedAssigneesIds === undefined || newSelectedAssigneesIds.length === oldSelectedAssigneesIds.length) {
      return;
    } else {
      this.refreshGrid();
    }
  }

  @Watch("selectedCallStatusesIds", { immediate: true })
  onSelectedCallStatusesIdsChange(newSelectedCallStatusesIds: number[], oldSelectedCallStatusesIds: number[]): void {
    if (oldSelectedCallStatusesIds === undefined || newSelectedCallStatusesIds.length === oldSelectedCallStatusesIds.length) {
      return;
    } else {
      this.refreshGrid();
    }
  }

  @Watch("selectedChannelMetadataTypesIds", { immediate: true })
  onSelectedChannelMetadataTypesIdsChange(newSelectedChannelMetadataTypesIds: number[], oldSelectedChannelMetadataTypesIds: number[]): void {
    if (oldSelectedChannelMetadataTypesIds === undefined || newSelectedChannelMetadataTypesIds.length === oldSelectedChannelMetadataTypesIds.length) {
      return;
    } else {
      this.getChannels(this.commonPayload);
      this.refreshGrid();
    }
  }

  @Watch("selectedChannelsIds", { immediate: true })
  onSelectedChannelsIdsChange(newSelectedChannelsIds: number[], oldSelectedChannelsIds: number[]): void {
    if (oldSelectedChannelsIds === undefined || newSelectedChannelsIds.length === oldSelectedChannelsIds.length) {
      return;
    } else {
      this.getAdTitles(this.commonPayload);
      this.getAdGroups(this.commonPayload);
      this.refreshGrid();
    }
  }

  @Watch("selectedClientsIds", { immediate: true })
  onSelectedClientsIdsChange(newSelectedClientsIds: number[], oldSelectedClientsIds: number[]): void {
    if (oldSelectedClientsIds === undefined || newSelectedClientsIds.length === oldSelectedClientsIds.length) {
      return;
    } else {
      this.setSelectedChannelsIds([]);
      this.getChannels(this.commonPayload);
      this.setSelectedAssetsIds([]);
      this.getAssets(this.commonPayload);
      this.setSelectedCommitmentsIds([]);
      this.getCommitments(this.commonPayload);
      this.setSelectedAdditionalStatusesIds([]);
      this.clearAdditionalStatuses();
      this.getAdditionalStatuses(this.commonPayload);
      this.getAssignees(this.commonPayload);
      this.refreshGrid();
    }
  }

  @Watch("selectedCommitmentsIds", { immediate: true })
  onSelectedCommitmentsIdsChange(newSelectedCommitmentsIds: number[], oldSelectedCommitmentsIds: number[]): void {
    if (oldSelectedCommitmentsIds === undefined || newSelectedCommitmentsIds.length === oldSelectedCommitmentsIds.length) {
      return;
    } else {
      this.refreshGrid();
    }
  }

  @Watch("selectedLeadStatusesIds", { immediate: true })
  onSelectedLeadStatusesIdsChange(newSelectedLeadStatusesIds: LeadStatus[], oldSelectedLeadStatusesIds: LeadStatus[]): void {
    if (oldSelectedLeadStatusesIds === undefined || newSelectedLeadStatusesIds.length === oldSelectedLeadStatusesIds.length) {
      return;
    } else {
      this.refreshGrid();
    }
  }

  @Watch("selectedSaleStatusesIds", { immediate: true })
  onSelectedSaleStatusesIdsChange(newSelectedSaleStatusesIds: LeadStatus[], oldSelectedSaleStatusesIds: LeadStatus[]): void {
    if (oldSelectedSaleStatusesIds === undefined || newSelectedSaleStatusesIds.length === oldSelectedSaleStatusesIds.length) {
      return;
    } else {
      this.setSelectedAdditionalStatusesIds([]);
      this.clearAdditionalStatuses();
      this.getAdditionalStatuses(this.commonPayload);
      this.refreshGrid();
    }
  }

  @Watch("selectedTags", { immediate: true })
  onSelectedTagsChange(newSelectedTags: string[], oldSelectedTags: string[]): void {
    if (oldSelectedTags === undefined || newSelectedTags.length === oldSelectedTags.length) {
      return;
    } else {
      this.refreshGrid();
    }
  }

  @Watch("showDeleted", { immediate: true })
  onShowDeletedChange(newShowDeletedValue: string, oldShowDeletedValue: string): void {
    if (newShowDeletedValue === oldShowDeletedValue || oldShowDeletedValue === undefined) {
      return;
    } else {
      this.refreshGrid();
    }
  }

  @Watch("showFavourite", { immediate: true })
  onShowFavouriteChange(newShowFavouriteValue: string, oldShowFavouriteValue: string): void {
    if (newShowFavouriteValue === oldShowFavouriteValue || oldShowFavouriteValue === undefined) {
      return;
    } else {
      this.refreshGrid();
    }
  }

  @Watch("showNotActiveCampaign", { immediate: true })
  onShowNotActiveCampaignChange(newShowNotActiveCampaignValue: string, oldShowNotActiveCampaignValue: string): void {
    if (newShowNotActiveCampaignValue === oldShowNotActiveCampaignValue || oldShowNotActiveCampaignValue === undefined) {
      return;
    } else {
      this.setSelectedChannelsIds([]);
      this.getChannels(this.commonPayload);
      this.setSelectedCommitmentsIds([]);
      this.clearCommitments();
      this.getCommitments(this.commonPayload);
      if (!newShowNotActiveCampaignValue) {
        this.getAdTitles(this.commonPayload);
        this.getAdGroups(this.commonPayload);
        this.refreshGrid();
      }
    }
  }

  @Watch("uniqueByCampaign", { immediate: true })
  onUniqueByCampaignChange(newUniqueValue: string, oldUniqueValue: string): void {
    if (newUniqueValue === oldUniqueValue || oldUniqueValue === undefined) {
      return;
    } else {
      this.refreshGrid();

      const cookieName = "do_not_show_unique_popup";
      const cookieValue: string = Vue.$cookies.get(cookieName);
      console.log("cookieValue", cookieValue);
      if (newUniqueValue && cookieValue !== "true") {
        this.$buefy.dialog.confirm({
          cancelText: i18n.t("OK").toString(),
          confirmText: i18n.t("Leads.UniquePopup.DoNotShow").toString(),
          message: i18n.t("Leads.UniquePopup.Text").toString(),
          onConfirm: () => {
            Vue.$cookies.set(cookieName, true, "1y");
          },
        });
      }
    }
  }

  //#endregion Watchers

  //#region Getters

  get adGroupsFiltered(): MultiselectItem[] {
    return this.adGroups.filter((i: MultiselectItem) => i.Title.toLowerCase().indexOf(this.adGroupsSearch.toLowerCase()) >= 0);
  }

  get adTitlesFiltered(): AdTitlesByLeads[] {
    return this.adTitles.filter((i: AdTitlesByLeads) => i.AdTitle.toLowerCase().indexOf(this.adTitlesSearch.toLowerCase()) >= 0);
  }

  get additionalStatusesFiltered(): SelectListItem[] {
    return this.additionalStatuses.filter((i: SelectListItem) => i.Text.toLowerCase().indexOf(this.additionalStatusesSearch.toLowerCase()) >= 0);
  }

  get assetsFiltered(): ClientProject[] {
    return this.assets.filter((i: ClientProject) => i.Title.toLowerCase().indexOf(this.assetsSearch.toLowerCase()) >= 0);
  }

  get assigneesFiltered(): Assignee[] {
    return this.assignees.filter((i: Assignee) => i.SalesmanName.toLowerCase().indexOf(this.assigneesSearch.toLowerCase()) >= 0);
  }

  get callStatuses(): MultiselectItem[] {
    return LeadService.getCallStatuses();
  }

  get channelMetadataTypesFiltered(): MultiselectItem[] {
    return ChannelService.getChannelMetadataTypes(this.channelMetadataTypesSearch.toLowerCase());
  }

  get channelsFiltered(): MultiselectItem[] {
    return this.channels.filter((i: MultiselectItem) => i.Title.toLowerCase().indexOf(this.channelsSearch.toLowerCase()) >= 0);
  }

  get clientsFiltered(): MultiselectItem[] {
    return this.clients.filter((i: MultiselectItem) => i.Title.toLowerCase().indexOf(this.clientsSearch.toLowerCase()) >= 0);
  }

  get commitmentsFiltered(): MultiselectItem[] {
    return this.commitments.filter((i: MultiselectItem) => i.Title.toLowerCase().indexOf(this.commitmentsSearch.toLowerCase()) >= 0);
  }

  get commonPayload(): CommonMultiselectPayload {
    return {
      clientIds: this.selectedClientsIds,
      showNotActiveCamp: this.showNotActiveCampaign,
      metadataIds: this.selectedChannelMetadataTypesIds,
      statusIds: this.selectedSaleStatusesIds.map((i: LeadStatus) => i.toString()),
      channelIds: this.selectedChannelsIds,
    };
  }

  get leadStatusesFiltered(): MultiselectItem[] {
    return LeadService.getAvailableLeadStatuses(this.leadStatusesSearch.toLowerCase());
  }

  get priceTypes(): MultiselectItem[] {
    const result: MultiselectItem[] = [];
    const keys: number[] = Object.keys(LeadPriceType)
      .filter((i) => parseInt(i) >= 0)
      .map((i) => parseInt(i));
    for (const key of keys) {
      const title: string = i18n.t(`Enums.LeadPriceType.${LeadPriceType[key]}`).toString();
      if (title.toLowerCase().indexOf(this.channelMetadataTypesSearch.toLowerCase()) >= 0) {
        result.push({
          ID: key.toString(),
          Title: title,
        });
      }
    }
    return result;
  }

  get saleStatusesFiltered(): MultiselectItem[] {
    return LeadService.getAvailableSaleStatuses();
  }

  //#endregion Getters

  //#region Lifecycle

  mounted(): void {
    this.token = this.guid();
    this.refreshGrid();
    this.getClients();
    this.getCommitments(this.commonPayload);
    this.getChannels(this.commonPayload);
  }

  //#endregion Lifecycle

  //#region Handlers

  onAdGroupsChange(adGroups: number[]): void {
    this.setSelectedAdGroupsIds(adGroups);
  }

  onAdTitlesChange(adTitles: string[]): void {
    this.setSelectedAdTitles(adTitles);
  }

  onAdditionalStatusesChange(additionalStatusesIds: number[]): void {
    this.setSelectedAdditionalStatusesIds(additionalStatusesIds);
  }

  onAssetsChange(assetsIds: string[]): void {
    this.setSelectedAssetsIds(assetsIds);
  }

  onAssigneesChange(assigneesIds: number[]): void {
    this.setSelectedAssigneesIds(assigneesIds);
  }

  onCallStatusesChange(selectedValue: number): void {
    // only if user select new value, not if deselect value
    const allCallsValue = -1;
    const enumValues = [0, 1];
    selectedValue = +selectedValue;
    let values = [...this.selectedCallStatusesIds];
    if (values.indexOf(selectedValue) >= 0) {
      const ind: number = values.indexOf(selectedValue);
      values.splice(ind, 1);
    } else {
      values.push(selectedValue);
      if (selectedValue === allCallsValue) {
        // if user picked 'all calls' remove all enum values
        values = values.filter((i: number) => i < 0);
      } else if (enumValues.indexOf(selectedValue) !== -1) {
        // if user picked one value of enum values,
        // remove 'all calls' if not all enum values picked
        // else - replace enum values with 'all calls'
        let i = 0;
        for (let v of enumValues) {
          if (values.indexOf(v) !== -1) {
            i++;
          }
        }
        if (i === enumValues.length) {
          values = values.filter((i: number) => enumValues.indexOf(i) === -1);
          values.push(allCallsValue);
        } else if (values.indexOf(allCallsValue) !== -1) {
          values = values.filter((i: number) => i !== allCallsValue);
        }
      }
    }
    this.setSelectedCallStatusesIds(values);
  }

  onChannelMetadataTypesChange(channelMetadataTypes: ChannelMetadataType[]): void {
    this.setSelectedChannelMetadataTypesIds(channelMetadataTypes);
  }

  onChannelsChange(channelIds: number[]): void {
    this.setSelectedChannelsIds(channelIds);
  }

  onClientChange(clientIds: number[]): void {
    this.setSelectedClientsIds(clientIds);
  }

  onCommitmentsChange(commitmentsIds: number[]): void {
    this.setSelectedCommitmentsIds(commitmentsIds);
  }

  onLeadStatusesChange(leadStatuses: LeadStatus[]): void {
    this.setSelectedLeadStatusesIds(leadStatuses);
  }

  onPriceTypeChange(priceTypeId: string): void {
    this.selectedPriceType = priceTypeId;
    this.refreshGrid();
  }

  onSaleStatusesChange(saleStatusesIds: string[]): void {
    this.setSelectedSaleStatusesIds(saleStatusesIds.map((i: string) => +i));
  }

  onTagKeydown(e: KeyboardEvent): void {
    if (e.key !== "Enter") {
      return;
    }
    const tag: string = (e.target as HTMLInputElement).value;
    if (tag === "") {
      return;
    }
    var newItem: MultiselectItem = {
      ID: tag,
      Title: tag,
    };
    let newTags = [...this.tags];
    newTags.push(newItem);
    this.setTags(newTags);

    let newSelectedTags = [...this.selectedTags];
    newSelectedTags.push(tag);
    this.onTagsChange(newSelectedTags);
  }

  onTagsChange(tags: string[]): void {
    this.setSelectedTags(tags);
  }

  //#endregion Handlers

  //#region Private methods

  private getFiltersString(filterCollection: FilterCollection): string {
    const filterStrings: string[] = [];
    const filterItemStrings: string[] = [];
    if (filterCollection.filters?.length) {
      for (const filter of filterCollection.filters) {
        filterItemStrings.push(this.getFiltersString(filter));
      }
    } else if (filterCollection.field) {
      filterItemStrings.push(`${filterCollection.field}~${filterCollection.operator}~${filterCollection.value}`);
    }
    if (filterItemStrings.length) {
      let filterItemString = "";
      if (filterItemStrings.length > 1) {
        filterItemString += "(";
      }
      filterItemString += filterItemStrings.join(`~${filterCollection.logic}~`);
      if (filterItemStrings.length > 1) {
        filterItemString += ")";
      }
      filterStrings.push(filterItemString);
    }
    return filterStrings.join(`~${filterCollection.logic}~`);
  }

  private guid() {
    const s4 = Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
    return "ss-s-s-s-sss".replace(/s/g, s4);
  }

  private refreshGrid(): void {
    this.token = this.guid();
    const filterCollection: FilterCollection = {
      logic: "and",
      filters: [],
    };
    if (this.selectedClientsIds.length) {
      filterCollection.filters?.push(this.setFilterArray(["ClientID"], this.selectedClientsIds, "eq"));
    }
    if (this.selectedChannelsIds.length) {
      filterCollection.filters?.push(this.setFilterArray(["ChannelID"], this.selectedChannelsIds, "eq"));
    }
    if (this.selectedChannelMetadataTypesIds.length) {
      filterCollection.filters?.push(this.setFilterArray(["ChannelMetadataType"], this.selectedChannelMetadataTypesIds, "eq"));
    }

    if (this.selectedCallStatusesIds.length) {
      const statusFilters: FilterCollection = {
        logic: "or",
        filters: [],
      };
      for (const status of this.selectedCallStatusesIds) {
        if (status == -2) {
          statusFilters.filters?.push({
            logic: "and",
            filters: [
              {
                field: "FoundMetadataType",
                operator: "neq",
                value: ChannelMetadataType.Phone.toString(),
              },
            ],
          });
        } else if (status == -1) {
          statusFilters.filters?.push({
            logic: "or",
            filters: [
              {
                field: "FoundMetadataType",
                operator: "eq",
                value: ChannelMetadataType.Phone.toString(),
              },
            ],
          });
        } else if (status == -3) {
          statusFilters.filters?.push({
            logic: "and",
            filters: [
              {
                field: "FoundMetadataType",
                operator: "eq",
                value: ChannelMetadataType.WhatsAppOrganic.toString(),
              },
              {
                field: "StatusCall",
                operator: "isnull",
              },
            ],
          });
        } else {
          statusFilters.filters?.push({
            logic: "and",
            filters: [
              {
                field: "FoundMetadataType",
                operator: "eq",
                value: ChannelMetadataType.Phone.toString(),
              },
              {
                field: "StatusCall",
                operator: "eq",
                value: status.toString(),
              },
            ],
          });
        }
      }
      filterCollection.filters?.push(statusFilters);
    }

    if (this.selectedCommitmentsIds.length) {
      filterCollection.filters?.push(this.setFilterArray(["ProjectPerformanceId"], this.selectedCommitmentsIds, "eq"));
    }
    if (this.selectedAssigneesIds.length) {
      filterCollection.filters?.push(this.setFilterArray(["AssigneeID"], this.selectedAssigneesIds, "eq"));
    }
    if (this.selectedTags.length) {
      filterCollection.filters?.push(this.setFilterArray(["Tag"], this.selectedTags, "contains"));
    }
    if (this.selectedAssetsIds.length) {
      filterCollection.filters?.push(this.setFilterArray(["AssetId"], this.selectedAssetsIds, "eq"));
    }
    if (this.uniqueByCampaign) {
      filterCollection.filters?.push(this.setFilterArray(["LeadEntryStatusID"], [LeadStatus.Doubled], "neq"));
    }
    if (this.showFavourite) {
      filterCollection.filters?.push(this.setFilterArray(["Favourite"], ["true"], "eq"));
    }
    if (this.selectedAdTitles.length) {
      filterCollection.filters?.push(this.setFilterArray(["AdTitle", "FacebookAdName"], this.selectedAdTitles, "eq"));
    }
    if (this.selectedAdGroupsIds.length) {
      filterCollection.filters?.push(this.setFilterArray(["FacebookAdSetId", "GoogleAdGroupId"], this.selectedAdGroupsIds, "eq"));
    }

    if (this.selectedLeadStatusesIds.length) {
      const statusFilters: FilterCollection = {
        logic: "or",
        filters: [],
      };
      for (const status of this.selectedLeadStatusesIds) {
        if (status == LeadStatus.Doubled) {
          statusFilters.filters?.push({
            field: "LeadEntryStatusID",
            operator: "eq",
            value: status.toString(),
          });
        } else {
          statusFilters.filters?.push({
            logic: "and",
            filters: [
              {
                field: "LeadEntryStatusID",
                operator: "neq",
                value: LeadStatus.Doubled.toString(),
              },
            ],
          });
        }
      }
      filterCollection.filters?.push(statusFilters);
    }

    if (this.selectedSaleStatusesIds.length) {
      const statusFilters: FilterCollection = {
        logic: "or",
        filters: [],
      };
      for (const status of this.selectedSaleStatusesIds) {
        if (status === LeadStatus.InProcess) {
          statusFilters.filters?.push({
            field: "IsInProcess",
            operator: "eq",
            value: "true",
          });
        } else if (status === LeadStatus.Closed) {
          statusFilters.filters?.push({
            field: "IsClosed",
            operator: "eq",
            value: "true",
          });
        } else if (status === LeadStatus.Meeting) {
          statusFilters.filters?.push({
            field: "Meeting",
            operator: "eq",
            value: "true",
          });
        } else if (status === LeadStatus.NotGood) {
          statusFilters.filters?.push({
            field: "IsBad",
            operator: "eq",
            value: "true",
          });
        } else if (status === LeadStatus.New) {
          statusFilters.filters?.push({
            logic: "and",
            filters: [
              {
                field: "IsBad",
                operator: "eq",
                value: "false",
              },
              {
                field: "IsClosed",
                operator: "eq",
                value: "false",
              },
              {
                field: "IsInProcess",
                operator: "eq",
                value: "false",
              },
              {
                field: "Meeting",
                operator: "eq",
                value: "false",
              },
            ],
          });
        }
      }
      filterCollection.filters?.push(statusFilters);
    }

    if (this.selectedAdditionalStatusesIds.length) {
      filterCollection.filters?.push(this.setFilterArray(["AdditionalStatusId"], this.selectedAdditionalStatusesIds, "eq"));
    }

    const filtersString: string = this.getFiltersString(filterCollection);
    const leadsPayload: LeadsPayload = {
      dateFrom: moment(this.datePeriod[0]).format("YYYY-MM-DD"),
      dateTo: moment(this.datePeriod[1]).format("YYYY-MM-DD"),
      filter: filtersString,
      page: this.page,
      pageSize: this.pageSize,
      priceType: this.selectedPriceType,
      showDeleted: this.showDeleted,
      sort: this.sort,
      token: this.token,
      unique: this.uniqueByCampaign,
    };
    this.getLeads(leadsPayload);
    this.getLeadsStatisticByAdditionalStatus(leadsPayload);
    this.getLeadsStatisticByCallStatus(leadsPayload);
    this.getLeadsStatisticByPhoneAndForms(leadsPayload);
    this.getLeadsStatisticBySaleStatus(leadsPayload);
    this.getLeadsStatisticChannelMetadataType(leadsPayload);
    this.getLeadsStatisticDealMeetingsLeads(leadsPayload);

    const leadsStatisticByCampaignsPayload: LeadsStatisticByCampaignsPayload = {
      clientSelected: this.selectedClientsIds.length > 0,
      dateFrom: moment(this.datePeriod[0]).format("YYYY-MM-DD"),
      dateTo: moment(this.datePeriod[1]).format("YYYY-MM-DD"),
      filter: filtersString,
      token: this.token,
    };
    this.getLeadsStatisticByCampaigns(leadsStatisticByCampaignsPayload);
  }

  private setFilterArray(fieldNames: string[], fieldValue: number[] | string[], operation: string): FilterCollection {
    const filters: FilterCollection[] = [];
    for (const fieldValueItem of fieldValue) {
      for (const fieldName of fieldNames) {
        filters.push({
          field: fieldName,
          operator: operation,
          value: typeof fieldValueItem === "string" ? `'${fieldValueItem}'` : fieldValueItem.toString(),
        });
      }
    }
    return {
      logic: "or",
      filters: filters,
    };
  }

  //#endregion Private methods
}

interface FilterCollection {
  field?: string;
  logic?: string;
  operator?: string;
  value?: string;
  filters?: FilterCollection[];
}
