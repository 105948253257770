










import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class IconBase extends Vue {
  @Prop({ default: 18 }) readonly height!: number;
  @Prop({ default: "currentColor" }) readonly iconColor!: string;
  @Prop({ default: "box" }) readonly iconName!: string;
  @Prop({ default: "0 0 18 18" }) readonly viewBox!: string;
  @Prop({ default: 18 }) readonly width!: number;
}
