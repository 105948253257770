import { Module } from "vuex";
import { MainState } from "@/models/states/MainState";
import { RootState } from "@/models/states/RootState";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const mainModule: Module<MainState, RootState> = {
  namespaced: true,
  state: {
    userData: {},
  },
  getters,
  mutations,
  actions,
};
export default mainModule;
