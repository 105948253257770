import i18n from "@/i18n";
import { AvailableChannelMetadataTypes, ChannelMetadataType } from "@/models/ChannelMetadataType";
import { MultiselectItem } from "@/models/MultiselectItem";

export default {
  getChannelMetadataTypes(filter?: string): MultiselectItem[] {
    const result: MultiselectItem[] = [];
    for (const channelMetadataType of AvailableChannelMetadataTypes) {
      const title: string = i18n.t(`Enums.ChannelMetadataType.${ChannelMetadataType[channelMetadataType]}`).toString();
      if (!filter || title.toLowerCase().indexOf(filter.toLowerCase()) >= 0) {
        result.push({
          ID: channelMetadataType.toString(),
          Title: title,
        });
      }
    }
    return result;
  },
};
