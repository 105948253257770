import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Leads from "@/views/Leads.vue";
import Channels from "@/views/Channels.vue";
import ChannelEdit from "@/views/ChannelEdit.vue";
import ChannelsInnerDemo from "@/views/ChannelsInnerDemo.vue";
import Smartphones from "@/views/Smartphones.vue";
import NotFound from "@/views/NotFound.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/ContactForms/Index",
    name: "Leads",
    component: Leads,
  },
  {
    path: "/Channels/Index",
    name: "Channels",
    component: Channels,
  },
  {
    path: "/Channels/Edit",
    name: "Channel Edit",
    component: ChannelEdit,
  },
  {
    path: "/channels/inner",
    name: "Channels Inner Demo",
    component: ChannelsInnerDemo,
  },
  {
    path: "/Phones/Index",
    name: "Smartphones",
    component: Smartphones,
  },
  {
    path: "*",
    name: "NotFound",
    redirect: "/ContactForms/Index",
    // component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "is-active",
  routes,
});

export default router;
