
import { ChartOptions } from "chart.js";
import { Bar, mixins } from "vue-chartjs";
import { Component, Mixins } from "vue-property-decorator";
import "chartjs-plugin-datalabels";

@Component({
  extends: Bar,
  mixins: [mixins.reactiveProp],
})
export default class SmartphonesGraphBar extends Mixins(mixins.reactiveProp, Bar) {
  options: ChartOptions = {
    legend: {
      display: false,
    },
    layout: {
      padding: 0,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            // display: false,
          },
          ticks: {
            suggestedMin: 0,
            // suggestedMax: 100,
            callback: function (value: number): string {
              if (value > 1000) {
                return Number((value / 1000).toString()) + "K";
              } else {
                return value + "";
              }
            },
          },
        },
      ],
      xAxes: [
        {
          // type: "time",
          // time: {
          //   unit: "day",
          //   displayFormats: {
          //     day: "DD/MM/YYYY",
          //   },
          // },
        },
      ],
    },
    responsive: true,
    maintainAspectRatio: false,
    responsiveAnimationDuration: 0,
    plugins: {
      datalabels: {
        anchor: "end",
        align: "end",
        textAlign: "center",
        color: "#fff",
        offset: -25,
        // clamp: false,
        clip: true,
        formatter: function (context) {
          if (context > 1000) {
            return context / 1000 + "K";
          }
        },
      },
    },
  };

  mounted(): void {
    this.renderChart(this.chartData, this.options);
  }
}
