export enum LeadStatus {
  New = 0,
  Doubled = 1,
  RepeatedIn1Min = 3,
  InProcess = 4,
  Good = 6,
  Meeting = 7,
  SentEstimate = 8,
  Closed = 9,
  NotClosed = 10,
  NotGood = 11,
  Archives = 12,
  Deleted = 50,
  Ignored = 60,
}

export const AvailableLeadStatuses: LeadStatus[] = [LeadStatus.New, LeadStatus.Doubled];

export const AvailableSaleStatuses: LeadStatus[] = [LeadStatus.New, LeadStatus.InProcess, LeadStatus.Meeting, LeadStatus.NotGood, LeadStatus.Closed];
