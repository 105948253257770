
import { ChartOptions } from "chart.js";
import { Pie, mixins } from "vue-chartjs";
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component({
  extends: Pie,
  mixins: [mixins.reactiveProp],
})
export default class LeadsGraphPie extends Mixins(mixins.reactiveProp, Pie) {
  @Prop() readonly options!: ChartOptions;

  mounted(): void {
    this.renderChart(this.chartData, this.options);
  }
}
