
















import { Component, Vue } from "vue-property-decorator";
import SmartphonesHeadline from "../components/Smartphones/SmartphonesHeadline.vue";
import SmartphonesFilters from "../components/Smartphones/SmartphonesFilters.vue";
import SmartphonesGraphs from "../components/Smartphones/SmartphonesGraphs.vue";
import SmartphonesGrid from "../components/Smartphones/SmartphonesGrid.vue";
import VueMq from "vue-mq";

import SmartphonesGridContentEdit from "../components/Smartphones/SmartphonesGridContentEdit.vue";

Vue.use(VueMq, {
  breakpoints: {
    sm: 576,
    md: 992,
    lg: Infinity,
  },
  defaultBreakpoint: "sm",
});

@Component({
  components: {
    SmartphonesHeadline,
    SmartphonesFilters,
    SmartphonesGraphs,
    SmartphonesGrid,
    SmartphonesGridContentEdit,
  },
})
export default class Smartphones extends Vue {
  public smartphonesGraphsVisible = true;

  public smartphoneEditVisible = false;

  public toggleGraphs(): void {
    this.smartphonesGraphsVisible = !this.smartphonesGraphsVisible;
  }

  public toggleSmartphoneEdit(): void {
    this.smartphoneEditVisible = !this.smartphoneEditVisible;
    this.smartphoneEditVisible ? this.popupBodyOn() : this.popupBodyOff();
  }

  public popupBodyOn(): void {
    document.body.style.top = "-" + window.scrollY + "px";
    document.body.style.position = "fixed";
  }

  public popupBodyOff(): void {
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  }
}
