

































































































import VueMq from "vue-mq";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { UserData } from "@/models/UserData";
import HeaderNotifications from "./HeaderNotifications.vue";
import HeaderUser from "./HeaderUser.vue";
import IconBase from "../IconBase.vue";
import IconCaretDown from "../icons/IconCaretDown.vue";
import IconPlaySquare from "../icons/IconPlaySquare.vue";
import IconBars from "../icons/IconBars.vue";
import IconTimes from "../icons/IconTimes.vue";
import IconCaretLeft from "../icons/IconCaretLeft.vue";

Vue.use(VueMq, {
  breakpoints: {
    sm: 576,
    md: 992,
    lg: Infinity,
  },
  defaultBreakpoint: "sm",
});

const namespace = "mainModule";

@Component({
  components: {
    IconBase,
    IconCaretDown,
    IconPlaySquare,
    IconBars,
    IconTimes,
    IconCaretLeft,
    HeaderNotifications,
    HeaderUser,
  },
})
export default class Header extends Vue {
  //#region Vuex getters and actions

  @Getter("userData", { namespace }) userData!: UserData;
  @Action("getUserData", { namespace }) getUserData!: () => Promise<UserData>;

  //#endregion Vuex getters and actions

  //#region Lifecycle

  mounted(): void {
    this.getUserData();
  }

  //#endregion Lifecycle

  public mobileMenu = false;

  get currentLanguageTitle(): string {
    switch (this.$i18n.locale) {
      case "en":
        return "English";
      case "he":
        return "עברית";
    }
    return this.$i18n.locale;
  }

  public showMobileMenu(): void {
    this.mobileMenu = true;
    this.popupBodyOn();
  }

  public hideMobileMenu(): void {
    this.mobileMenu = false;
    this.popupBodyOff();
  }

  public popupBodyOn(): void {
    document.body.style.top = "-" + window.scrollY + "px";
    document.body.style.position = "fixed";
  }

  public popupBodyOff(): void {
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  }

  changeLocale(locale: string): void {
    this.$i18n.locale = locale;
    localStorage.locale = locale;
  }
}
