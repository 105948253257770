




















import VueMq from "vue-mq";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { ContactForm } from "@/models/ContactForm";
import LeadsGridHeadline from "./LeadsGridHeadline.vue";
import LeadsGridContent from "./LeadsGridContent.vue";
import IconBase from "../IconBase.vue";
import IconSearch from "../icons/IconSearch.vue";
import i18n from "../../i18n";
import { LeadsGridColumn } from "../../models/LeadsGridColumn";

const namespace = "leadsModule";

Vue.use(VueMq, {
  breakpoints: {
    sm: 576,
    md: 992,
    lg: Infinity,
  },
  defaultBreakpoint: "sm",
});

@Component({
  components: {
    LeadsGridHeadline,
    LeadsGridContent,
    IconBase,
    IconSearch,
  },
})
export default class LeadsGrid extends Vue {
  @Getter("leads", { namespace }) leads!: ContactForm[];

  leadsGridColumns: LeadsGridColumn[] = [
    { id: 0, name: "ID", label: "ID", visible: true },
    { id: 1, name: "FullName", label: i18n.t("Leads.Grid.Columns.FullName").toString(), class: "cell-full-name", visible: true, width: 137 },
    { id: 2, name: "CreateDate", label: i18n.t("Leads.Grid.Columns.CreateDate").toString(), visible: true, width: 137 },
    { id: 3, name: "Phone", label: i18n.t("Leads.Grid.Columns.Phone").toString(), class: "cell-phone", visible: true, width: 128 },
    { id: 4, name: "Email", label: i18n.t("Leads.Grid.Columns.Email").toString(), class: "cell-email", visible: true, width: 128 },
    { id: 5, name: "Client", label: i18n.t("Leads.Grid.Columns.Client").toString(), class: "cell-client", visible: true, width: 128 },
    { id: 6, name: "CampaignTitle", label: i18n.t("Leads.Grid.Columns.CampaignTitle").toString(), class: "cell-channel", visible: true, width: 128 },
    {
      id: 7,
      name: "ChannelMetadataTypeText",
      label: i18n.t("Leads.Grid.Columns.ChannelMetadataTypeText").toString(),
      class: "cell-advertising-channel",
      visible: true,
      width: 128,
    },
    {
      id: 8,
      name: "LeadApiTypeText",
      label: i18n.t("Leads.Grid.Columns.LeadApiTypeText").toString(),
      class: "cell-integration-type",
      visible: true,
      width: 128,
    },
    {
      id: 9,
      name: "Tag",
      label: i18n.t("Leads.Grid.Columns.Tag").toString(),
      class: "cell-product-tag",
      visible: true,
      width: 128,
    },
    {
      id: 10,
      name: "LeadEntryStatus",
      label: i18n.t("Leads.Grid.Columns.LeadEntryStatus").toString(),
      class: "cell-welcome-status",
      visible: true,
      width: 128,
    },
    {
      id: 11,
      name: "SaleStatusStr",
      label: i18n.t("Leads.Grid.Columns.SaleStatus").toString(),
      class: "cell-sales-stage",
      visible: true,
      width: 128,
    },
    {
      id: 12,
      name: "AdditionalStatus",
      label: i18n.t("Leads.Grid.Columns.AdditionalStatus").toString(),
      class: "cell-current-sales-status",
      visible: true,
      width: 128,
    },
    {
      id: 13,
      name: "CallStatus",
      label: i18n.t("Leads.Grid.Columns.CallStatus").toString(),
      class: "cell-call-status",
      visible: true,
      width: 128,
    },
    {
      id: 14,
      name: "CallSecondsStr",
      label: i18n.t("Leads.Grid.Columns.CallSeconds").toString(),
      class: "cell-call-duration",
      visible: true,
      width: 128,
    },
    {
      id: 15,
      name: "Price",
      label: i18n.t("Leads.Grid.Columns.Price").toString(),
      visible: true,
      width: 128,
    },
    {
      id: 16,
      name: "Keyword",
      label: i18n.t("Leads.Grid.Columns.Keyword").toString(),
      class: "cell-keyword",
      visible: true,
      width: 128,
    },
    {
      id: 17,
      name: "AdGroup",
      label: i18n.t("Leads.Grid.Columns.AdGroupTitle").toString(),
      class: "cell-ad-group",
      visible: true,
      width: 128,
    },
    {
      id: 18,
      name: "ProjectPerformanceTitle",
      label: i18n.t("Leads.Grid.Columns.ProjectPerformanceTitle").toString(),
      visible: true,
      width: 128,
    },
    {
      id: 19,
      name: "AssigneeName",
      label: i18n.t("Leads.Grid.Columns.AssigneeName").toString(),
      class: "cell-salesperson",
      visible: true,
      width: 128,
    },
    {
      id: 20,
      name: "MeetingStartDate",
      label: i18n.t("Leads.Grid.Columns.MeetingStartDate").toString(),
      class: "cell-meeting-date",
      visible: true,
      width: 128,
    },
    {
      id: 21,
      name: "MeetingStatus",
      label: i18n.t("Leads.Grid.Columns.MeetingStatus").toString(),
      class: "cell-meeting-status",
      visible: true,
      width: 128,
    },
    {
      id: 22,
      name: "Amount",
      label: i18n.t("Leads.Grid.Columns.Amount").toString(),
      class: "cell-transaction-sum",
      visible: true,
      width: 128,
    },
    {
      id: 23,
      name: "AssetTitle",
      label: i18n.t("Leads.Grid.Columns.AssetTitle").toString(),
      visible: true,
      width: 128,
    },
  ];
}
