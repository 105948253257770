






































































import { ChartData, ChartOptions } from "chart.js";
import moment from "moment";
import VueMq from "vue-mq";
import { Component, Vue } from "vue-property-decorator";
import IconBase from "../IconBase.vue";
import IconChartPie from "../icons/IconChartPie.vue";
import IconChartLine from "../icons/IconChartLine.vue";
import IconCalendarTime from "../icons/IconCalendarTime.vue";
import IconQuestionCircle from "../icons/IconQuestionCircle.vue";
import SmartphonesGraphBar from "./SmartphonesGraphBar.vue";
import SmartphonesGraphDoughnut from "./SmartphonesGraphDoughnut.vue";

Vue.use(VueMq, {
  breakpoints: {
    sm: 576,
    md: 992,
    lg: Infinity,
  },
  defaultBreakpoint: "sm",
});

// const timeFormat = "MM/DD/YYYY HH:mm";

// function newDate(days: number) {
//   return moment().add(days, "d").toDate();
// }
// function newDateString(days: number) {
//   return moment().add(days, "d").format(timeFormat);
// }

@Component({
  components: {
    IconBase,
    IconChartPie,
    IconChartLine,
    IconCalendarTime,
    IconQuestionCircle,
    SmartphonesGraphBar,
    SmartphonesGraphDoughnut,
  },
})
export default class SmartphonesGraphs extends Vue {
  chartOptionsDoughnut: ChartOptions = {
    legend: {
      display: false,
    },
    layout: {
      padding: 0,
    },
    tooltips: {
      enabled: false,
    },
    cutoutPercentage: 83,
    aspectRatio: 1,
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    responsiveAnimationDuration: 0,
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  smartphonesGraphDoughnut1ChartData: ChartData = {
    datasets: [
      {
        label: "Dataset 1",
        backgroundColor: ["#269339", "#e9eaf0"],
        data: [9025, 6000],
      },
    ],
    labels: ["שיחות שנענו", ""],
  };

  smartphonesGraphDoughnut2ChartData: ChartData = {
    datasets: [
      {
        label: "Dataset 2",
        backgroundColor: ["#DE473C", "#e9eaf0"],
        data: [5000, 8000],
      },
    ],
    labels: ["שיחות שלא נענו", ""],
  };

  smartphonesGraphBarChartData: ChartData = {
    // labels: [newDate(0), newDate(1), newDate(2), newDate(3), newDate(4), newDate(5), newDate(6)],
    labels: ["day 1", "day 2", "day 3", "day 4", "day 5", "day 6", "day 7"],
    datasets: [
      {
        label: "משך שיחה בדקות",
        backgroundColor: "#0093D0",
        barThickness: 40,
        maxBarThickness: 40,
        minBarLength: 2,
        barPercentage: 1,
        categoryPercentage: 0.5,
        data: [2400, 1500, 500, 1600, 2300, 1200, 2700],
      },
      {
        label: "כמות שיחות",
        backgroundColor: "#FF9724",
        barThickness: 40,
        maxBarThickness: 40,
        minBarLength: 2,
        barPercentage: 1,
        categoryPercentage: 0.5,
        data: [2600, 1300, 2200, 1700, 1400, 2500, 2100],
      },
    ],
  };

  smartphonesGraphBarChartDataMobile: ChartData = {
    labels: ["day 1", "day 2"],
    datasets: [
      {
        label: "משך שיחה בדקות",
        backgroundColor: "#0093D0",
        barThickness: 40,
        maxBarThickness: 40,
        minBarLength: 2,
        barPercentage: 1.0,
        categoryPercentage: 0.5,
        data: [2400, 1500],
      },
      {
        label: "כמות שיחות",
        backgroundColor: "#FF9724",
        barThickness: 40,
        maxBarThickness: 40,
        minBarLength: 2,
        barPercentage: 1,
        categoryPercentage: 0.5,
        data: [2600, 1300],
      },
    ],
  };
}
