

































































































import { Component, Prop, Vue } from "vue-property-decorator";
import ChannelsFiltersDropdowns from "@/components/Channels/ChannelsFiltersDropdowns.vue";
import ChannelsFiltersDate from "@/components/Channels/ChannelsFiltersDate.vue";
import IconBase from "../IconBase.vue";
import IconPlus from "../icons/IconPlus.vue";
import IconUserSearch from "../icons/IconUserSearch.vue";
import IconTimes from "../icons/IconTimes.vue";
import IconSearch from "../icons/IconSearch.vue";
import IconCaretLeft from "../icons/IconCaretLeft.vue";
import VueMq from "vue-mq";

Vue.use(VueMq, {
  breakpoints: {
    sm: 576,
    md: 992,
    lg: Infinity,
  },
  defaultBreakpoint: "sm",
});

@Component({
  components: {
    ChannelsFiltersDropdowns,
    ChannelsFiltersDate,
    IconBase,
    IconPlus,
    IconUserSearch,
    IconTimes,
    IconSearch,
    IconCaretLeft,
  },
})
export default class ChannelsHeadline extends Vue {
  @Prop() readonly page!: string;

  public mobileFilters = false;

  public showMobileFilters(): void {
    this.mobileFilters = true;
    this.popupBodyOn();
  }

  public hideMobileFilters(): void {
    this.mobileFilters = false;
    this.popupBodyOff();
  }

  public popupBodyOn(): void {
    document.body.style.top = "-" + window.scrollY + "px";
    document.body.style.position = "fixed";
  }

  public popupBodyOff(): void {
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  }
}
