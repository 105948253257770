

































import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { UserData } from "@/models/UserData";
import IconBase from "../IconBase.vue";
import IconBell from "../icons/IconBell.vue";
import IconTimes from "../icons/IconTimes.vue";

const namespace = "mainModule";

@Component({
  components: {
    IconBase,
    IconBell,
    IconTimes,
  },
})
export default class HeaderNotifications extends Vue {
  //#region Vuex getters and actions

  @Getter("userData", { namespace }) userData!: UserData;

  //#endregion Vuex getters and actions

  public accNotifications = false;
}
