




























































































































































import { ChartData, ChartOptions } from "chart.js";
import moment from "moment";
import VueMq from "vue-mq";
import { Component, Vue } from "vue-property-decorator";
import IconBase from "../IconBase.vue";
import ChannelsGraphLine from "./ChannelsGraphLine.vue";
import IconChartPie from "../icons/IconChartPie.vue";
import IconChartLine from "../icons/IconChartLine.vue";
import IconCalendarTime from "../icons/IconCalendarTime.vue";
import IconQuestionCircle from "../icons/IconQuestionCircle.vue";
import ChannelsGraphPie from "./ChannelsGraphPie.vue";

Vue.use(VueMq, {
  breakpoints: {
    sm: 576,
    md: 992,
    lg: Infinity,
  },
  defaultBreakpoint: "sm",
});

const timeFormat = "MM/DD/YYYY HH:mm";

function newDate(days: number) {
  return moment().add(days, "d").toDate();
}
function newDateString(days: number) {
  return moment().add(days, "d").format(timeFormat);
}

@Component({
  components: {
    IconBase,
    IconChartPie,
    IconChartLine,
    IconCalendarTime,
    IconQuestionCircle,
    ChannelsGraphLine,
    ChannelsGraphPie,
  },
})
export default class ChannelsGraphs extends Vue {
  channelsGraphActive = 0;

  chartOptions: ChartOptions = {
    legend: {
      display: false,
    },
    layout: {
      padding: 0,
    },
    aspectRatio: 1,
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    hover: {
      animationDuration: 200,
    },
    responsiveAnimationDuration: 0,
    plugins: {
      datalabels: {
        align: "end",
        color: "#fff",
        offset: -5,
        padding: 0,
        textAlign: "center",
        font: {
          weight: 600,
          size: 13,
          family: "Assistant",
        },
      },
    },
  };

  channelsGraphPie1ChartData: ChartData = {
    datasets: [
      {
        label: "Dataset 1",
        backgroundColor: ["#0f69c9", "#ff9724"],
        data: [1325, 2680],
      },
    ],
    labels: ["Facebook", "Google"],
  };

  channelsGraphPie2ChartData: ChartData = {
    datasets: [
      {
        label: "Dataset 2",
        backgroundColor: ["#0f69c9", "#ff9724"],
        data: [1325, 2680],
      },
    ],
    labels: ["נתונים", "נתונים", "נתונים", "נתונים"],
  };

  channelsGraphPie3ChartData: ChartData = {
    datasets: [
      {
        label: "Dataset 3",
        backgroundColor: ["#ff9724", "#0f69c9", "#0093d0"],
        data: [4460, 2385, 2400],
      },
    ],
    labels: ["נתונים", "נתונים", "נתונים", "נתונים"],
  };

  channelsGraphPie4ChartData: ChartData = {
    datasets: [
      {
        label: "Dataset 4",
        backgroundColor: ["#ff9724", "#0f69c9", "#269339", "#435C65"],
        data: [4580, 3200, 1380, 1380],
      },
    ],
    labels: ["נתונים", "נתונים", "נתונים", "נתונים"],
  };

  channelsGraphPie5ChartData: ChartData = {
    datasets: [
      {
        label: "Dataset 4",
        backgroundColor: ["#ff9724", "#0f69c9", "#269339", "#435C65"],
        data: [4580, 3200, 1380, 1380],
      },
    ],
    labels: ["נתונים", "נתונים", "נתונים", "נתונים"],
  };

  channelsGraphPie6ChartData: ChartData = {
    datasets: [
      {
        label: "Dataset 4",
        backgroundColor: ["#ff9724", "#0f69c9", "#269339", "#435C65"],
        data: [4580, 3200, 1380, 1380],
      },
    ],
    labels: ["נתונים", "נתונים", "נתונים", "נתונים"],
  };

  channelsGraphLineChartData: ChartData = {
    labels: [newDate(0), newDate(1), newDate(2), newDate(3), newDate(4), newDate(5), newDate(6), newDate(7), newDate(8), newDate(9), newDate(10), newDate(11), newDate(12)],
    datasets: [
      {
        label: "Data 1",
        data: [
          {
            x: newDateString(0),
            y: 305,
          },
          {
            x: newDateString(1),
            y: 220,
          },
          {
            x: newDateString(2),
            y: 230,
          },
          {
            x: newDateString(3),
            y: 265,
          },
          {
            x: newDateString(4),
            y: 195,
          },
          {
            x: newDateString(5),
            y: 170,
          },
          {
            x: newDateString(6),
            y: 164,
          },
          {
            x: newDateString(7),
            y: 168,
          },
          {
            x: newDateString(8),
            y: 171,
          },
          {
            x: newDateString(9),
            y: 199,
          },
          {
            x: newDateString(10),
            y: 240,
          },
          {
            x: newDateString(11),
            y: 290,
          },
          {
            x: newDateString(12),
            y: 210,
          },
        ],
        fill: true,
        borderColor: "#0093d0",
        borderWidth: 2,
        backgroundColor: "rgba(176, 217, 255, 0.2)",
        // backgroundColor: 'linear-gradient(to bottom, rgba(176, 217, 255, 1), rgba(176, 217, 255, 0))',
        // lineTension: 0
      },
      {
        label: "Data 2",
        // backgroundColor: "#f87979",
        data: [
          {
            x: newDateString(0),
            y: 105,
          },
          {
            x: newDateString(1),
            y: 171,
          },
          {
            x: newDateString(2),
            y: 199,
          },
          {
            x: newDateString(3),
            y: 240,
          },
          {
            x: newDateString(4),
            y: 290,
          },
          {
            x: newDateString(5),
            y: 210,
          },
          {
            x: newDateString(6),
            y: 220,
          },
          {
            x: newDateString(7),
            y: 230,
          },
          {
            x: newDateString(8),
            y: 265,
          },
          {
            x: newDateString(9),
            y: 195,
          },
          {
            x: newDateString(10),
            y: 170,
          },
          {
            x: newDateString(11),
            y: 164,
          },
          {
            x: newDateString(12),
            y: 168,
          },
        ],
        fill: false,
        borderColor: "#ff9724",
        borderWidth: 2,
        // lineTension: 0
      },
      {
        label: "Data 3",
        // backgroundColor: "#f87979",
        data: [
          {
            x: newDateString(0),
            y: 265,
          },
          {
            x: newDateString(1),
            y: 195,
          },
          {
            x: newDateString(2),
            y: 170,
          },
          {
            x: newDateString(3),
            y: 164,
          },
          {
            x: newDateString(4),
            y: 168,
          },
          {
            x: newDateString(5),
            y: 171,
          },
          {
            x: newDateString(6),
            y: 199,
          },
          {
            x: newDateString(7),
            y: 240,
          },
          {
            x: newDateString(8),
            y: 290,
          },
          {
            x: newDateString(9),
            y: 210,
          },
          {
            x: newDateString(10),
            y: 305,
          },
          {
            x: newDateString(11),
            y: 220,
          },
          {
            x: newDateString(12),
            y: 230,
          },
        ],
        fill: false,
        borderColor: "#269339",
        borderWidth: 2,
        // lineTension: 0
      },
    ],
  };
}
