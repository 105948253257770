










import { Component, Vue } from "vue-property-decorator";
// import SmartphonesFiltersSelected from "./SmartphonesFiltersSelected.vue";
import SmartphonesFiltersDropdowns from "./SmartphonesFiltersDropdowns.vue";
import SmartphonesFiltersDate from "./SmartphonesFiltersDate.vue";

@Component({
  components: {
    // SmartphonesFiltersSelected,
    SmartphonesFiltersDropdowns,
    SmartphonesFiltersDate,
  },
})
export default class SmartphonesFilters extends Vue {
  SmartphonesFilter1SelectedOptions = [1, 2];
}
