












import { Component, Vue } from "vue-property-decorator";
import Fragment from "vue-fragment";
import { Action } from "vuex-class";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/he";
import IconBase from "../IconBase.vue";
import IconCalendar from "../icons/IconCalendar.vue";

Vue.use(Fragment.Plugin);
const namespace = "leadsModule";

@Component({
  components: {
    IconBase,
    IconCalendar,
    DatePicker,
  },
})
export default class LeadsFiltersDate extends Vue {
  @Action("setDatePeriod", { namespace }) setDatePeriod!: (datePeriod: Date[]) => void;

  public thisMonth(): Date[] {
    let start = new Date();
    start.setDate(1);
    let end = new Date();
    end.setMonth(end.getMonth() + 1);
    end.setDate(0);
    return [start, end];
  }

  public LeadsFilterDates: Date[] = this.thisMonth();

  LeadsFilterDatesShortcuts = [
    {
      name: "today",
      text: "היום",
      onClick(): Date[] {
        const start = new Date();
        return [start, start];
      },
    },
    {
      name: "yesterday",
      text: "אתמול",
      onClick(): Date[] {
        let start = new Date();
        start.setDate(start.getDate() - 1);
        return [start, start];
      },
    },
    {
      name: "prev_week",
      text: "שבוע שעבר",
      onClick(): Date[] {
        let start = new Date();
        start.setDate(start.getDate() - start.getDay() - 7);
        let end = new Date();
        end.setDate(start.getDate() + 6);
        console.log("start:" + start);
        console.log("end:" + end);
        return [start, end];
      },
    },
    {
      name: "month",
      text: "החודש",
      onClick(): Date[] {
        let start = new Date();
        start.setDate(1);
        let end = new Date();
        end.setMonth(start.getMonth() + 1);
        end.setDate(0);

        console.log("start:" + start);
        console.log("end:" + end);
        return [start, end];
      },
    },
    {
      name: "prev_month",
      text: "חודש שעבר",
      onClick(): Date[] {
        let start = new Date();
        start.setDate(1);
        start.setMonth(start.getMonth() - 1);
        let end = new Date();
        end.setDate(0);
        return [start, end];
      },
    },
    {
      name: "30_days",
      text: "30 יום",
      onClick(): Date[] {
        let start = new Date();
        start.setDate(start.getDate() - 30);
        const end = new Date();
        return [start, end];
      },
    },
    {
      name: "all_time",
      text: "כל הזמן",
      onClick(): Date[] {
        let start = new Date();
        start.setDate(start.getDate() - 365);
        const end = new Date();
        return [start, end];
      },
    },
  ];

  beforeMount(): void {
    this.LeadsFilterDates = this.thisMonth();
    this.setDatePeriod(this.LeadsFilterDates);
  }

  onDatePeriodChange(datePeriod: Date[]): void {
    this.setDatePeriod(datePeriod);
  }
}
