export enum ChannelMetadataType {
  None = 0,
  GoogleAdwords = 1,
  GoogleOrganic = 2,
  FacebookAds = 3,
  Direct = 4,
  FacebookOrganic = 5,
  Referral = 6,
  Phone = 7,
  InstagramAds = 8,
  Linkedin = 9,
  Outbrain = 10,
  Newsletter = 11,
  Youtube = 12,
  FacebookAdLeadPage = 13,
  FacebookAdLeadCampaign = 14,
  InstagramAdLeadCampaign = 15,
  TV = 16,
  Radio = 17,
  Print = 18,
  Crm = 19,
  FacebookComments = 20,
  FacebookMessenger = 21,
  CrmCallCenter = 22,
  CrmImport = 23,
  WhatsAppOrganic = 24,
}

export const AvailableChannelMetadataTypes: ChannelMetadataType[] = [
  ChannelMetadataType.GoogleAdwords,
  ChannelMetadataType.GoogleOrganic,
  ChannelMetadataType.FacebookAds,
  ChannelMetadataType.FacebookAdLeadCampaign,
  ChannelMetadataType.FacebookOrganic,
  ChannelMetadataType.InstagramAds,
  ChannelMetadataType.InstagramAdLeadCampaign,
  ChannelMetadataType.Phone,
  ChannelMetadataType.Referral,
  ChannelMetadataType.Direct,
  ChannelMetadataType.TV,
  ChannelMetadataType.Radio,
  ChannelMetadataType.Print,
  ChannelMetadataType.Youtube,
  ChannelMetadataType.Outbrain,
  ChannelMetadataType.Linkedin,
  ChannelMetadataType.Newsletter,
  ChannelMetadataType.Crm,
  ChannelMetadataType.CrmCallCenter,
  ChannelMetadataType.CrmImport,
  ChannelMetadataType.FacebookComments,
  ChannelMetadataType.FacebookMessenger,
];
