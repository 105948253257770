import { Config } from "@/models/Config";
import configData from "../data/config.json";

const config: Config = configData as Config;

export default {
  exportLeadsToExcelUrl: config.serverUrl + "ContactForms/ExportToExcel",
  loginUrl: config.serverUrl + "login",
  logoutUrl: config.serverUrl + "Authentication/LogoutFromFrontClient",
  contactFormsUrl: config.serverUrl + "ContactForms/Leads_Read",
  leadsStatisticChannelMetadataTypeUrl: config.serverUrl + "ContactForms/LeadsStatistic_ChannelMetadataType",
  leadsStatisticDealMeetingsLeadsUrl: config.serverUrl + "ContactForms/LeadsStatistic_DealMeetingsLeads",
  leadsStatisticByAdditionalStatusUrl: config.serverUrl + "ContactForms/LeadsStatistic_LeadsByAdditionalStatus",
  leadsStatisticByCallStatusUrl: config.serverUrl + "ContactForms/LeadsStatistic_LeadsByCallStatus",
  leadsStatisticByCampaignsUrl: config.serverUrl + "ContactForms/LeadsStatistic_LeadsByCampaigns",
  leadsStatisticByPhoneAndFormsUrl: config.serverUrl + "ContactForms/LeadsStatistic_LeadsByPhoneAndForms",
  leadsStatisticBySaleStatusUrl: config.serverUrl + "ContactForms/LeadsStatistic_LeadsBySaleStatus",
  additionalStatusesUrl: config.serverUrl + "ContactForms/SaleStatusList_Read",
  assigneesUrl: config.serverUrl + "ContactForms/Salesmans_Read",
  adGroupsUrl: config.serverUrl + "MultiSelect/AdGroupsByChannel",
  adTitlesUrl: config.serverUrl + "MultiSelect/AdTitles",
  assetsUrl: config.serverUrl + "MultiSelect/Assets",
  channelsUrl: config.serverUrl + "MultiSelect/Channels",
  clientsUrl: config.serverUrl + "MultiSelect/Clients",
  commitmentsUrl: config.serverUrl + "MultiSelect/Commitments",
  userDataUrl: config.serverUrl + "Users/GetUserData",
};
