import { render, staticRenderFns } from "./ChannelsFiltersDropdowns.vue?vue&type=template&id=bc87d090&scoped=true&"
import script from "./ChannelsFiltersDropdowns.vue?vue&type=script&lang=ts&"
export * from "./ChannelsFiltersDropdowns.vue?vue&type=script&lang=ts&"
import style0 from "./ChannelsFiltersDropdowns.vue?vue&type=style&index=0&id=bc87d090&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc87d090",
  null
  
)

export default component.exports