import { MutationTree } from "vuex";
import { ClientProject } from "@/models/ClientProject";
import { AdTitlesByLeads, Assignee, MultiselectItem, SelectListItem } from "@/models/MultiselectItem";
import { LeadsState } from "@/models/states/LeadsState";
import { ContactForm } from "@/models/ContactForm";
import { LeadStatistic, LeadStatisticExtended } from "@/models/LeadStatistic";
import { LeadStatus } from "@/models/LeadStatus";

const mutations: MutationTree<LeadsState> = {
  setAdGroups(state: LeadsState, adGroups: MultiselectItem[]) {
    state.adGroups = adGroups;
  },

  setAdTitles(state: LeadsState, adTitles: AdTitlesByLeads[]) {
    state.adTitles = adTitles;
  },

  setAdditionalStatuses(state: LeadsState, additionalStatuses: SelectListItem[]) {
    state.additionalStatuses = additionalStatuses;
  },

  setAssets(state: LeadsState, assets: ClientProject[]) {
    state.assets = assets;
  },

  setAssignees(state: LeadsState, assignees: Assignee[]) {
    state.assignees = assignees;
  },

  setChannels(state: LeadsState, channels: MultiselectItem[]) {
    state.channels = channels;
  },

  setClients(state: LeadsState, clients: MultiselectItem[]) {
    state.clients = clients;
  },

  setCommitments(state: LeadsState, commitments: MultiselectItem[]) {
    state.commitments = commitments;
  },

  setDatePeriod(state: LeadsState, datePeriod: Date[]) {
    state.datePeriod = datePeriod;
  },

  setLeads(state: LeadsState, leads: ContactForm[]) {
    state.leads = leads;
  },

  setLeadsStatisticByAdditionalStatus(state: LeadsState, leadStatistics: LeadStatistic[]) {
    state.leadsStatisticByAdditionalStatus = leadStatistics;
  },

  setLeadsStatisticByCallStatus(state: LeadsState, leadStatistics: LeadStatistic[]) {
    state.leadsStatisticByCallStatus = leadStatistics;
  },

  setLeadsStatisticByCampaigns(state: LeadsState, leadStatistics: LeadStatistic[]) {
    state.leadsStatisticByCampaigns = leadStatistics;
  },

  setLeadsStatisticByPhoneAndForms(state: LeadsState, leadStatistics: LeadStatistic[]) {
    state.leadsStatisticByPhoneAndForms = leadStatistics;
  },

  setLeadsStatisticBySaleStatus(state: LeadsState, leadStatistics: LeadStatistic[]) {
    state.leadsStatisticBySaleStatus = leadStatistics;
  },

  setLeadsStatisticChannelMetadataType(state: LeadsState, leadStatistics: LeadStatistic[]) {
    state.leadsStatisticChannelMetadataType = leadStatistics;
  },

  setLeadsStatisticDealMeetingsLeads(state: LeadsState, leadStatistics: LeadStatisticExtended[]) {
    state.leadsStatisticDealMeetingsLeads = leadStatistics;
  },

  setSelectedAdditionalStatusesIds(state: LeadsState, selectedAdditionalStatusesIds: number[]) {
    state.selectedAdditionalStatusesIds = selectedAdditionalStatusesIds;
  },

  setSelectedAdGroupsIds(state: LeadsState, selectedAdGroupsIds: number[]) {
    state.selectedAdGroupsIds = selectedAdGroupsIds;
  },

  setSelectedAdTitles(state: LeadsState, selectedAdTitles: string[]) {
    state.selectedAdTitles = selectedAdTitles;
  },

  setSelectedAssetsIds(state: LeadsState, selectedAssetsIds: string[]) {
    state.selectedAssetsIds = selectedAssetsIds;
  },

  setSelectedAssigneesIds(state: LeadsState, selectedAssigneesIds: number[]) {
    state.selectedAssigneesIds = selectedAssigneesIds;
  },

  setSelectedCallStatusesIds(state: LeadsState, selectedCallStatusesIds: number[]) {
    state.selectedCallStatusesIds = selectedCallStatusesIds;
  },

  setSelectedChannelMetadataTypesIds(state: LeadsState, selectedChannelMetadataTypesIds: number[]) {
    state.selectedChannelMetadataTypesIds = selectedChannelMetadataTypesIds;
  },

  setSelectedChannelsIds(state: LeadsState, selectedChannelsIds: number[]) {
    state.selectedChannelsIds = selectedChannelsIds;
  },

  setSelectedClientsIds(state: LeadsState, selectedClientsIds: number[]) {
    state.selectedClientsIds = selectedClientsIds;
  },

  setSelectedCommitmentsIds(state: LeadsState, selectedCommitmentsIds: number[]) {
    state.selectedCommitmentsIds = selectedCommitmentsIds;
  },

  setSelectedLeadStatusesIds(state: LeadsState, selectedLeadStatusesIds: LeadStatus[]) {
    state.selectedLeadStatusesIds = selectedLeadStatusesIds;
  },

  setSelectedSaleStatusesIds(state: LeadsState, selectedSaleStatusesIds: LeadStatus[]) {
    state.selectedSaleStatusesIds = selectedSaleStatusesIds;
  },

  setSelectedTags(state: LeadsState, selectedTags: string[]) {
    state.selectedTags = selectedTags;
  },

  setShowDeleted(state: LeadsState, showDeleted: boolean) {
    state.showDeleted = showDeleted;
  },

  setShowFavourite(state: LeadsState, showFavourite: boolean) {
    state.showFavourite = showFavourite;
  },

  setShowNotActiveCampaign(state: LeadsState, showNotActiveCampaign: boolean) {
    state.showNotActiveCampaign = showNotActiveCampaign;
  },

  setTags(state: LeadsState, tags: MultiselectItem[]) {
    state.tags = tags;
  },

  setUniqueByCampaign(state: LeadsState, uniqueByCampaign: boolean) {
    state.uniqueByCampaign = uniqueByCampaign;
  },
};
export default mutations;
