import { GetterTree } from "vuex";
import { UserData } from "@/models/UserData";
import { RootState } from "@/models/states/RootState";
import { MainState } from "@/models/states/MainState";

const getters: GetterTree<MainState, RootState> = {
  userData(state): UserData {
    return state.userData;
  },
};
export default getters;
