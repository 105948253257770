import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "@/models/states/RootState";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import leadsModule from "./modules/leads";
import mainModule from "./modules/main";

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: {
    version: "1",
  },

  getters,
  mutations,
  actions,

  modules: {
    leadsModule,
    mainModule,
  },
};
export default new Vuex.Store<RootState>(store);
