















































































































































import { ChartData, ChartDataSets, ChartOptions, ChartPoint } from "chart.js";
import "chartjs-plugin-datalabels";
import moment from "moment";
import VueMq from "vue-mq";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { LeadStatistic, LeadStatisticExtended } from "@/models/LeadStatistic";
import IconBase from "../IconBase.vue";
import LeadsGraphLine from "./LeadsGraphLine.vue";
import IconChartPie from "../icons/IconChartPie.vue";
import IconChartLine from "../icons/IconChartLine.vue";
import IconCalendarTime from "../icons/IconCalendarTime.vue";
import IconQuestionCircle from "../icons/IconQuestionCircle.vue";
import LeadsGraphPie from "./LeadsGraphPie.vue";
import i18n from "../../i18n";

Vue.use(VueMq, {
  breakpoints: {
    sm: 576,
    md: 992,
    lg: Infinity,
  },
  defaultBreakpoint: "sm",
});

const namespace = "leadsModule";

@Component({
  components: {
    IconBase,
    IconChartPie,
    IconChartLine,
    IconCalendarTime,
    IconQuestionCircle,
    LeadsGraphLine,
    LeadsGraphPie,
  },
})
export default class LeadsGraphs extends Vue {
  @Getter("leadsStatisticByAdditionalStatus", { namespace }) leadsStatisticByAdditionalStatus!: LeadStatistic[];
  @Getter("leadsStatisticByCallStatus", { namespace }) leadsStatisticByCallStatus!: LeadStatistic[];
  @Getter("leadsStatisticByCampaigns", { namespace }) leadsStatisticByCampaigns!: LeadStatistic[];
  @Getter("leadsStatisticByPhoneAndForms", { namespace }) leadsStatisticByPhoneAndForms!: LeadStatistic[];
  @Getter("leadsStatisticBySaleStatus", { namespace }) leadsStatisticBySaleStatus!: LeadStatistic[];
  @Getter("leadsStatisticChannelMetadataType", { namespace }) leadsStatisticChannelMetadataType!: LeadStatistic[];
  @Getter("leadsStatisticDealMeetingsLeads", { namespace }) leadsStatisticDealMeetingsLeads!: LeadStatistic[];

  leadsGraphActive = 0;

  lineChartOptions: ChartOptions = {
    legend: {
      display: false,
    },
    layout: {
      padding: 0,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          ticks: {
            suggestedMin: 0,
            suggestedMax: 10,
          },
        },
      ],
      xAxes: [
        {
          type: "time",
          time: {
            unit: "day",
            displayFormats: {
              day: "DD/MM/YYYY",
            },
            tooltipFormat: "DD/MM/YYYY",
          },
        },
      ],
    },
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 2,
      },
    },
    animation: {
      duration: 0,
    },
    hover: {
      animationDuration: 200,
    },
    responsiveAnimationDuration: 0,
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  pieChartOptions: ChartOptions = {
    legend: {
      display: false,
    },
    layout: {
      padding: 10,
    },
    aspectRatio: 1,
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    hover: {
      animationDuration: 200,
    },
    responsiveAnimationDuration: 0,
    plugins: {
      datalabels: {
        align: "end",
        color: "#fff",
        offset: 5,
        padding: 0,
        textAlign: "center",
        font: {
          weight: 600,
          size: 13,
          family: "Assistant",
        },
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  leadsStatisticByAdditionalStatusChartData: ChartData = {};
  leadsStatisticByCallStatusChartData: ChartData = {};
  leadsStatisticByCampaignsChartData: ChartData = {};
  leadsStatisticByPhoneAndFormsChartData: ChartData = {};
  leadsStatisticBySaleStatusChartData: ChartData = {};
  leadsStatisticChannelMetadataTypeChartData: ChartData = {};
  leadsStatisticDealMeetingsLeadsChartData: LineChartData = {};

  //#region Watches

  @Watch("leadsStatisticByAdditionalStatus", { immediate: true })
  onLeadsStatisticByAdditionalStatusChange(newStats: LeadStatistic[], oldStats: LeadStatistic[]): void {
    if (newStats) {
      this.leadsStatisticByAdditionalStatusChartData = this.getChartData(newStats);
    }
  }

  @Watch("leadsStatisticByCallStatus", { immediate: true })
  onLeadsStatisticByCallStatusChange(newStats: LeadStatistic[], oldStats: LeadStatistic[]): void {
    if (newStats) {
      this.leadsStatisticByCallStatusChartData = this.getChartData(newStats);
    }
  }

  @Watch("leadsStatisticByCampaigns", { immediate: true })
  onLeadsStatisticByCampaignsChange(newStats: LeadStatistic[], oldStats: LeadStatistic[]): void {
    if (newStats) {
      this.leadsStatisticByCampaignsChartData = this.getChartData(newStats);
    }
  }

  @Watch("leadsStatisticByPhoneAndForms", { immediate: true })
  onLeadsStatisticByPhoneAndFormsChange(newStats: LeadStatistic[], oldStats: LeadStatistic[]): void {
    if (newStats) {
      this.leadsStatisticByPhoneAndFormsChartData = this.getChartData(newStats);
    }
  }

  @Watch("leadsStatisticBySaleStatus", { immediate: true })
  onLeadsStatisticBySaleStatusChange(newStats: LeadStatistic[], oldStats: LeadStatistic[]): void {
    if (newStats) {
      this.leadsStatisticBySaleStatusChartData = this.getChartData(newStats);
    }
  }

  @Watch("leadsStatisticChannelMetadataType", { immediate: true })
  onLeadsStatisticChannelMetadataTypeChange(newStats: LeadStatistic[], oldStats: LeadStatistic[]): void {
    if (newStats) {
      this.leadsStatisticChannelMetadataTypeChartData = this.getChartData(newStats);
    }
  }

  @Watch("leadsStatisticDealMeetingsLeads", { immediate: true })
  onLeadsStatisticDealMeetingsLeadsChange(newStats: LeadStatisticExtended[], oldStats: LeadStatisticExtended[]): void {
    if (newStats) {
      this.leadsStatisticDealMeetingsLeadsChartData = this.getLineChartData(newStats);
    }
  }

  //#endregion Watches

  getChartData(stats: LeadStatistic[]): ChartData {
    if (stats.length > 0 && !stats[0].Color) {
      var predefinedColors = ["#0f69c9", "#ff9724", "#0093d0", "#269339", "#de473c", "#66bee3"];
      for (let i = 0; i < stats.length; i++) {
        stats[i].Color = predefinedColors[i % predefinedColors.length];
      }
    }
    const colors: string[] = stats.map((i) => i.Color);
    const data: number[] = stats.map((i) => i.Value);
    const labels: string[] = stats.map((i) => i.Text);
    return {
      datasets: [
        {
          backgroundColor: colors,
          data,
        },
      ],
      labels,
    };
  }

  getLineChartData(stats: LeadStatisticExtended[]): LineChartData {
    const colors: string[] = [];
    const leadsDataset: ChartDataSets = {
      borderColor: "#0093d0",
      borderWidth: 2,
      fill: false,
      label: i18n.t("LeadsGraphs.Leads").toString(),
    };
    const dealsDataset: ChartDataSets = {
      borderColor: "#ff9724",
      borderWidth: 2,
      fill: false,
      label: i18n.t("LeadsGraphs.Deals").toString(),
    };
    const meatingsDataset: ChartDataSets = {
      borderColor: "#269339",
      borderWidth: 2,
      fill: false,
      label: i18n.t("LeadsGraphs.Meetings").toString(),
    };
    const labels: string[] = [];
    if (stats.length > 0) {
      const leadsData: ChartPoint[] = [];
      const dealsData: ChartPoint[] = [];
      const meetingsData: ChartPoint[] = [];
      for (let i = 0; i < stats.length; i++) {
        const stat = stats[i];
        const x: string = moment(stat.Day).format("MM/DD/YYYY");
        leadsData.push({
          x,
          y: stat.Leads,
        });
        dealsData.push({
          x,
          y: stat.Deals,
        });
        meetingsData.push({
          x,
          y: stat.Meetings,
        });
      }
      leadsDataset.data = leadsData;
      dealsDataset.data = dealsData;
      meatingsDataset.data = meetingsData;
      if (this.lineChartOptions.scales && this.lineChartOptions.scales.yAxes && this.lineChartOptions.scales.yAxes[0].ticks) {
        this.lineChartOptions.scales.yAxes[0].ticks.suggestedMax = Math.max(...[...stats.map((i) => i.Leads), ...stats.map((i) => i.Deals), ...stats.map((i) => i.Meetings)]);
      }
    }
    return {
      chartData: {
        datasets: [leadsDataset, dealsDataset, meatingsDataset],
        labels,
      },
      totalDeals: stats.map((i) => i.Deals).reduce((partialSum: number, a: number) => partialSum + a, 0),
      totalLeads: stats.map((i) => i.Leads).reduce((partialSum: number, a: number) => partialSum + a, 0),
      totalMeetings: stats.map((i) => i.Meetings).reduce((partialSum: number, a: number) => partialSum + a, 0),
    };
  }

  getSumOfStatsValues(stats: LeadStatistic[], filterValue: number): number {
    if (stats) {
      if (filterValue) {
        stats = stats.filter((i: LeadStatistic) => i.Id === filterValue);
      }
      return stats.map((i) => i.Value as number).reduce((partialSum: number, a: number) => partialSum + a, 0);
    } else {
      return 0;
    }
  }
}

interface LineChartData {
  totalDeals?: number;
  totalLeads?: number;
  totalMeetings?: number;
  chartData?: ChartData;
}
