





















































































import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import LeadsFiltersDropdowns from "@/components/Leads/LeadsFiltersDropdowns.vue";
import LeadsFiltersDate from "@/components/Leads/LeadsFiltersDate.vue";
import IconBase from "../IconBase.vue";
import IconUserSearch from "../icons/IconUserSearch.vue";
import IconTimes from "../icons/IconTimes.vue";
import IconSearch from "../icons/IconSearch.vue";
import VueMq from "vue-mq";

Vue.use(VueMq, {
  breakpoints: {
    sm: 576,
    md: 992,
    lg: Infinity,
  },
  defaultBreakpoint: "sm",
});
const namespace = "leadsModule";

@Component({
  components: {
    LeadsFiltersDropdowns,
    LeadsFiltersDate,
    IconBase,
    IconUserSearch,
    IconTimes,
    IconSearch,
  },
})
export default class LeadsHeadline extends Vue {
  @Action("setShowDeleted", { namespace }) setShowDeleted!: (showDeleted: boolean) => void;
  @Action("setShowFavourite", { namespace }) setShowFavourite!: (showFavourite: boolean) => void;
  @Action("setShowNotActiveCampaign", { namespace }) setShowNotActiveCampaign!: (showNotActiveCampaign: boolean) => void;
  @Action("setUniqueByCampaign", { namespace }) setUniqueByCampaign!: (uniqueByCampaign: boolean) => void;

  public mobileFilters = false;

  public showMobileFilters(): void {
    this.mobileFilters = true;
    this.popupBodyOn();
  }

  public hideMobileFilters(): void {
    this.mobileFilters = false;
    this.popupBodyOff();
  }

  public onShowDeletedChange(showDeleted: boolean): void {
    this.setShowDeleted(showDeleted);
  }

  public onShowFavouriteChange(showFavourite: boolean): void {
    this.setShowFavourite(showFavourite);
  }

  public onShowNotActiveCampaignChange(showNotActiveCampaign: boolean): void {
    this.setShowNotActiveCampaign(showNotActiveCampaign);
  }

  public onUniqueByCampaignChange(uniqueByCampaign: boolean): void {
    this.setUniqueByCampaign(uniqueByCampaign);
  }

  public popupBodyOn(): void {
    document.body.style.top = "-" + window.scrollY + "px";
    document.body.style.position = "fixed";
  }

  public popupBodyOff(): void {
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  }
}
