







































































import { Component, Vue } from "vue-property-decorator";
import IconBase from "../IconBase.vue";
import IconCaretDown from "../icons/IconCaretDown.vue";
import IconTimes from "../icons/IconTimes.vue";

@Component({
  components: {
    IconBase,
    IconCaretDown,
    IconTimes,
  },
})
export default class SmartphonesGridContentEdit extends Vue {
  emitToggleSmartphoneEdit2(): void {
    this.$emit("toggle-smartphone-edit2");
  }

  phoneIsActive = true;
  SmartphonesCompaignConnectedCurrent = { text: "דמו פיקס - Google ads - דמו גוגל " };
  SmartphonesCompaignConnectedOptions = [
    { value: "option1", text: "1" },
    { value: "option2", text: "2" },
    { value: "option3", text: "3" },
    { value: "option4", text: "4" },
  ];
}
