import { ActionTree } from "vuex";
import { UserData } from "@/models/UserData";
import { MainState } from "@/models/states/MainState";
import { RootState } from "@/models/states/RootState";
import ApiService from "@/services/ApiService";

const actions: ActionTree<MainState, RootState> = {
  getUserData({ commit }): Promise<UserData> {
    commit("setUserData", null);
    return new Promise((resolve, reject) => {
      ApiService.getUserData()
        .then((data: UserData) => {
          commit("setUserData", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setUserData({ commit }, userData: UserData[]): void {
    commit("setUserData", userData);
  },
};
export default actions;
